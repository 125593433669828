'use strict';

class Footer {
    // Constructor
    constructor() {
        this.window = $(window);
        this.document = $(document);
    }

    // Init before frameworks
    init() {
        
        this.backToTopButton = $('.tps-back-to-top');
        this.footerNode = $('#footer');

        this.backToTopClick();
        this.backToTopUpdatePosition()
        this.window.scroll(function() {
            TPS.footer.onScroll();
        }).scroll();
    }

    // Init after frameworks
    initAfterFrameworks() {}

    // Back to top (click event)
    backToTopClick() {
        var self = TPS.footer;

        this.backToTopButton.on('click', function (e) {
            e.preventDefault();
            TPS.Utility.smoothScroll('#form');
        });
    }

    // On scroll
    onScroll() {
        this.requestTick();
    }

    // On scroll (request tick // to avoid overload window.onscroll)
    requestTick() {
        if (! this.ticking) {
            (window.requestAnimationFrame
                || window.webkitRequestAnimationFrame
                || window.mozRequestAnimationFrame
                || window.msRequestAnimationFrame
                || window.oRequestAnimationFrame)(this.backToTopUpdatePosition);

            this.ticking = true;
        }
    }

    // Back to top (update node position)
    backToTopUpdatePosition() {
        var self = TPS.footer;
        var scrollTop = self.window.scrollTop();

        if (scrollTop > 0) {
            if (! self.backToTopButton.hasClass('show')) {
                self.backToTopButton.addClass('show');
                self.backToTopButton.fadeIn();
            }

            if (self.footerNode.position().top - scrollTop < self.window.height()) {
                self.backToTopButton.addClass('fixed');
            } else {
                self.backToTopButton.removeClass('fixed');
            }
        } else {
            self.backToTopButton.fadeOut().removeClass('show');
        }

        self.ticking = false;
    }
};

TPS.footer = new Footer();
