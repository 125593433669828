'use strict';

class HeaderSticky {
    // Constructor    

    constructor() {
        this.window = $(window);
        this.document = $(document);
        
    }

    // Init before frameworks
    init() {


    }

    // Init after frameworks
    initAfterFrameworks() {

        let _this = this;

        _this.setStickyHeader();

        _this.window.on('scroll', function (e) {
            _this.setStickyHeader();
        });
    }

    setStickyHeader() {
        let _window = this.window;
        let _header = $('#header');
        let _body = $('body');
        
        if (_window.scrollTop() > 0 || $('html').offset().top < 0 ) {
            _header.addClass('sticky-header-stuck');
            _body.addClass('sticky-header');
        } else {
            _header.removeClass('sticky-header-stuck');
            _body.removeClass('sticky-header');
        }
    }

};

TPS.HeaderSticky = new HeaderSticky();
