'use strict';

class TPSFormSteps {

    // Constructor

    constructor() {
        this.window = $(window);
        this.document = $(document);

    }

    init() {

        let _this = this;

        this.steppedFormSteps = 0;
        this.steppedFormsCurrentStep = 0;
        // stepped form wrapper
        this.steppedFormContainer = $("<div/>").addClass('tps-form-container');

        // stepped form controls wrapper (next/submit)
        this.controlsWrapper = $("<div/>").addClass('tps-form-controls');

        // standard foundation progress bar
        this.progressPercentageBar = $('<div/>').addClass('progress form-stepped-progress').attr({
            'role': 'progressbar',
            'tabindex': '0',
            'aria-valuenow': '0',
            'aria-valuemin': '0',
            'aria-valuemax': '100',
            'aria-valuetext': '',
        }).append('<span class="progress-meter" style="width: 0;"></span><span class="progress-meter-text"></span>');

        this.stepCategories = $('<div />').addClass('form-container-step-controls grid-x grid-padding-x align-center align-top');

        this.progressStepText = null;

        if ($('.form-container .EditingFormTable').length > 0) {
            $('.form-container .EditingFormTable').each(function () {
                TPS.FormSteps.renderForm($(this));
            });
        } else {
            // in case the form has been submitted, and the success message is showing
            // on the current page.
            $('.form-container .form-container-loader').fadeOut(function (e) {
                $(this).remove();
            });
        }

	}

    initAfterFrameworks() {}

    renderForm(tableObj) {
		var step = $('<div/>').addClass('tps-form-step');
        var isStepped = tableObj.parents('.form-container').hasClass('form-stepped');        
        TPS.FormSteps.steppedFormsCurrentStep = $('.form-container-inner').data("tpsStepIndex");

        var formStepCount = 0;

        tableObj.find('tr').each(function (rowIndex, rowElem) {
            var trObj = $(this);
            var trClass = trObj.attr('class');
            if (trClass && trClass.indexOf('EditingFormCategoryRow') == 0) {
				// append final step to stepped form
				// create a new step for stepped form
                step.appendTo(TPS.FormSteps.steppedFormContainer);

                // add element to hold step labels and indicators
                if (isStepped) {
                    var stepControl = $('<div/>').addClass('tps-form-step-control cell shrink').attr('data-target-step', formStepCount);
                    var stepIcon = $('<span/>').addClass('tps-form-step-icon').text(formStepCount + 1);
                    var stepLabel = $('<span/>').addClass('tps-form-step-control-label show-for-tablet').text(trObj.find('td').text());
                    stepIcon.appendTo(stepControl);
                    stepLabel.appendTo(stepControl);
                    stepControl.appendTo(TPS.FormSteps.stepCategories);
                    formStepCount++;
                }
                
                if (rowIndex != 0) {
                    step = $('<div/>').addClass('tps-form-step');
                }
            } else {
				// create html wrappers for questions
                var question = $("<div/>").addClass('tps-form-question');
                var label = $('<div/>').addClass('tps-form-label');
                var tip = $('<span data-tooltip data-alignment="right" data-position="bottom" tabindex="1"/>').addClass('fas fa-info-circle tps-form-tip');
                var input = $('<div/>').addClass('tps-form-input');
                var description = $('<p/>').addClass('tps-form-input-explaination');
                
                var isSubmit = false;
                var isHidden = false;
				// get label cell in form table layout
				var tdLabel = trObj.find('td').first();
				// get input cell in form table layout
                var tdInput = tdLabel.next();
                tdLabel.children().each(function () {

                    if (!$(this).hasClass('tps-not-required')) {
                        $(this).addClass('tps-field-required');
                    }
                    /*
                    */
                    if ($(this).text().length > 0) {
                        $(this).appendTo(label);
                    }
                });

                var descriptionText = tdLabel.attr('title') ? tdLabel.attr('title') : '';

                tdInput.children().each(function (k,v) {

                    if ($(v).hasClass('ExplanationText')){
                        tip.attr('title', $(this).text());
                        tip.appendTo(label);
                    } else {
                        // if submit, append to controls elem.                    
                        if ($(v).attr('type') == 'submit') {
                            isSubmit = true;
                            $(v).addClass('button');
                            $(v).appendTo(TPS.FormSteps.controlsWrapper);
                        }
                        // if a hidden input, prepend to form container
                        else if ($(v).children('input').attr('type') == 'hidden') {
                            isHidden = true;
                            $(v).prependTo(TPS.FormSteps.steppedFormContainer);
                        }
                        // otherwise, append to the input control wrapper
                        else {
                            $(v).appendTo(input);
                        }
                    }
                    if (descriptionText && descriptionText != '') {
                        description.text(descriptionText);
                        description.appendTo(input);
                    }
				});
				// if the row is not the submit button, append to step
				// otherwise append to the controls wrapper
                if (!isSubmit && !isHidden) {
                    if (label.children().length > 0) {
                        label.appendTo(question);
                    }
					input.appendTo(question);
					question.appendTo(step);
                }
            }
		});

		// append final step to stepped form
        step.appendTo(TPS.FormSteps.steppedFormContainer);
        // hide loader
        $('.form-container .form-container-loader').fadeOut(function (e) {
            $(this).remove();
        })
        // replace default form table layout with stepped form
        //TPS.FormSteps.steppedFormContainer.appendTo(tableObj.parent());
		tableObj.replaceWith(TPS.FormSteps.steppedFormContainer);
		// add form controls wrapper to stepped form
		TPS.FormSteps.controlsWrapper.appendTo(TPS.FormSteps.steppedFormContainer);
        TPS.FormSteps.steppedFormSteps = TPS.FormSteps.steppedFormContainer.find('.tps-form-step').length;
        if (TPS.FormSteps.steppedFormSteps > 1) {
            TPS.FormSteps.setupControls();            

            if (TPS.FormSteps.steppedFormContainer.parents('.form-container').hasClass('has-progress-bar')) {
				TPS.FormSteps.addProgressBar(TPS.FormSteps.steppedFormsCurrentStep);
			}

            if (isStepped) {
                TPS.FormSteps.steppedFormContainer.prepend(TPS.FormSteps.stepCategories);
            }

        }

        let _Form = $('.form-container .tps-form-container').first();

        _Form.addClass('active').show();
        _Form.find('.tps-form-step:eq(' + TPS.FormSteps.steppedFormsCurrentStep + ')').addClass('active').show();
        _Form.find('.tps-form-step-control[data-target-step="' + TPS.FormSteps.steppedFormsCurrentStep + '"]').addClass('active');
        
	}

	setupControls() {
        var nextBtn = $('<a/>').addClass('button button-next-step').text('Next Step').attr({
			'href': 'javascript:void(0)', 'tabindex' : '-1'
        });
        
        var prevBtn = $('<a/>').addClass('button button-prev-step').text('Previous Step').attr({
            'href': 'javascript:void(0)', 'tabindex': '-1'
        });

        nextBtn.prependTo(TPS.FormSteps.controlsWrapper);
        prevBtn.prependTo(TPS.FormSteps.controlsWrapper);

        TPS.FormSteps.controlsWrapper.find('[type="submit"]').addClass('hide');

        if ((TPS.FormSteps.steppedFormSteps - 1) == TPS.FormSteps.steppedFormsCurrentStep) {
            nextBtn.hide();
            prevBtn.show();
            TPS.FormSteps.controlsWrapper.find('[type="submit"]').removeClass('hide');
        } else if (TPS.FormSteps.steppedFormsCurrentStep != 0) {
            TPS.FormSteps.controlsWrapper.find('[type="submit"]').addClass('hide');
            nextBtn.show();
            prevBtn.show();
        } else {
            prevBtn.hide();
            nextBtn.show();
            TPS.FormSteps.controlsWrapper.find('[type="submit"]').addClass('hide');
        }

        this.document.on('click', '.button-next-step', function (e) {
            //e.preventDefault();
            TPS.FormSteps.navigateSteps(true, nextBtn, prevBtn);            
        });
        
        this.document.on('click', '.button-prev-step', function (e) {
            //e.preventDefault();
            TPS.FormSteps.navigateSteps(false, nextBtn, prevBtn);
        }); 
    }

    navigateSteps(f = true, nb, pb) {

        var activeStep, nextStep, nextStepIndex;
        activeStep = TPS.FormSteps.steppedFormContainer.find('.tps-form-step.active').first();
        nextStep = activeStep.next();
        if (!f) {
            nextStep = activeStep.prev();
        }
        activeStep.removeClass('active').hide();
        nextStep.addClass('active').show();
        // scroll to top of form step after new step is activated
        TPS.Utility.smoothScroll('.tps-form-step.active');
        nextStepIndex = nextStep.index('.tps-form-step');

        var activeStepIcon, nextStepIcon;
        activeStepIcon = TPS.FormSteps.steppedFormContainer.find('.tps-form-step-control.active').first();
        nextStepIcon = activeStepIcon.next();
        if (!f) {
            nextStepIcon = activeStepIcon.prev();
        }
        activeStepIcon.removeClass('active');
        nextStepIcon.addClass('active');


        TPS.FormSteps.setStepUrl(nextStepIndex);
        TPS.FormSteps.setProgress(nextStepIndex);

        // is the current step the LAST step? (next steps == 0)
        if (nextStep.next('.tps-form-step').length == 0) {
            nb.hide();
            pb.show();
            TPS.FormSteps.controlsWrapper.find('[type="submit"]').removeClass('hide');
        }
        // is the current step the NOT LAST or NOT FIRST step? (prev steps > 0 && next steps > 0)
        else if (nextStep.prev('.tps-form-step').length > 0 && nextStep.next('.tps-form-step').length > 0) {
            nb.show();
            pb.show();
            TPS.FormSteps.controlsWrapper.find('[type="submit"]').addClass('hide');
        }
        // is the current step the FIRST step? (prev steps == 0)
        else if (nextStep.prev('.tps-form-step').length == 0) {
            nb.show();
            pb.hide();
            TPS.FormSteps.controlsWrapper.find('[type="submit"]').addClass('hide');
        }

    }

	setStepUrl(progressStep) {

		// update or add the form step parameter
        var urlNew = TPS.FormSteps.updateQueryStringParam([{
			key : 'TpsStepIndex',
			value : progressStep
		}], true);
		// push the new search query string to the browser history
		window.history.pushState( { 'url' : urlNew }, '', urlNew );

		$('#form').attr('action', window.location.pathname + window.location.search);

	}

    updateQueryStringParam(paramArray, returnUrl) {
        var baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
            urlQueryString = document.location.search,
            params = '',
            newParams = '';

        $.each(paramArray, function () {
            if (urlQueryString) {
                var keyRegex = new RegExp('([\?&])' + this.key + '[^&]*');
                if (urlQueryString.match(keyRegex) !== null && this.value !== '') {
                    // replace query string parameter value when it exists has a new value
                    urlQueryString = urlQueryString.replace(keyRegex, '$1' + this.key + '=' + this.value);
                } else if (this.value !== '') {
                    // append new query string parameter when it has a value
                    urlQueryString = urlQueryString + '&' + this.key + '=' + this.value;
                } else {
                    // remove query string parameter when it has
                    // been changed to have no value
                    urlQueryString = urlQueryString.replace(keyRegex, '');
                }
            } else if (this.value && this.value !== '') {
                if (params == '') {
                    params += '?'
                } else {
                    params += '&'
                }
                params += this.key + '=' + this.value;
            }
        });

        if (urlQueryString) {
            newParams = urlQueryString;
        } else if (params != '') {
            newParams = params;
        }

        if (returnUrl) {
            if (newParams != '') {
                return baseUrl + newParams;
            } else {
                return baseUrl;
            }
        } else {
            if (newParams != '') {
                window.history.replaceState({}, "", baseUrl + newParams);
                window.location.search = newParams;
            } else {
                window.location = baseUrl;
            }
        }


    }

	addProgressBar(currentStep) {
		// create progress bar html and
		// append it to stepped form
		TPS.FormSteps.steppedFormContainer.prepend(TPS.FormSteps.progressPercentageBar);
		TPS.FormSteps.setProgress(currentStep);
	}

	setProgress(progressStep) {
		// set progress bar text and width
		var stepSize = 100 / (TPS.FormSteps.steppedFormSteps - 1);
		var progressSize = Math.floor((stepSize * progressStep));

		/* never show a 100% complete status when on last step */
		if(progressSize == 100){
			progressSize = 90;
		}

		var progressBar = TPS.FormSteps.steppedFormContainer.parents('.form-stepped').find('.form-stepped-progress');
		var progressText = 'Progress: ' + progressSize + '% Completed';
		//
		progressBar.attr({
			'aria-valuenow' : progressSize,
			'aria-valuetext' : progressText
		});

		progressBar.find('.progress-meter').css({'width' : progressSize + '%'});
		progressBar.find('.progress-meter-text').text(progressText);

		if(!TPS.FormSteps.progressStepText) {
			TPS.FormSteps.progressStepText = $('<p/>').appendTo($('.tps-form-steps-controls'));
		}

		/* progress bar set to 0, step counter set to 1 */
		var progressStepText = (progressStep + 1);

		TPS.FormSteps.progressStepText.text('Page ' + progressStepText + ' of ' + TPS.FormSteps.steppedFormSteps)
	}
}

TPS.FormSteps = new TPSFormSteps();
