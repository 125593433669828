'use strict';

class MessageHub {
    constructor() {
        this.loader = null;
        this.pager = null;
        this.tableSelector = '.tps-messagehub-table';
        this.tableSortHeaderSelector = '.tps-messagehub-table-sortheader';

        this.replyFormSelector = '.tps-messagehub-replyform';
        this.replyFormInputItemID = null;
        this.replyFormInputMessage = null;
        this.replyFormInputConsent = null;
        this.replyFormBtnSend = null;
        this.replyFormFeedback = null;

        this.replyFormTOUReveal = null;
        this.replyFormTOUAgree = null;
        this.replyFormTOUDecline = null;

        this.inputTeacherName = null;
        this.inputOrder = null;
        this.inputOrderBy = null;
        this.inputType = null;
        this.btnTeacherName = null;
        this.ddStatus = null;
        this.ddSort = null;

        this.params = {
            teacherName: null,
            status: null,
            orderBy: null,
            sort: null, // A or D
            currentPage: 1,
            pageSize: 10
        };

        this.pagesTotal = 5; // TODO: get from WS
    }

    // Init before frameworks
    init() {
        this.pager = document.getElementById('messagehub-pager');
        this.loader = document.querySelector('.tps-messagehub-loader');

        this.inputTeacherName = document.getElementById('messagehub-input-search');
        this.inputOrder = document.getElementById('messagehub-input-order');
        this.inputOrderBy = document.getElementById('messagehub-input-orderby');
        this.inputType = document.getElementById('messagehub-input-type');
        this.btnTeacherName = document.getElementById('messagehub-button-search');
        this.ddStatus = document.getElementById('messagehub-select-status');
        this.ddSort = document.getElementById('messagehub-select-sort');
        this.replyFormInputItemID = document.getElementById('messagehub-replyform-itemid');
        this.replyFormInputMessage = document.getElementById('messagehub-replyform-message');
        this.replyFormInputConsent = document.getElementById('messagehub-replyform-consent');
        this.replyFormBtnSend = document.getElementById('messagehub-replyform-submit');
        this.replyFormFeedback = document.querySelector('.tps-messagehub-replyform-feedback');
        this.replyFormTOUReveal = document.getElementById('messagehub-reveal-tou');
        this.replyFormTOUAgree = document.getElementById('messagehub-reveal-tou-accept');
        this.replyFormTOUDecline = document.getElementById('messagehub-reveal-tou-decline');

        if (document.querySelector(this.tableSelector)) {
            this.loadTable();
            this.initTableEvents();
        }

        if (document.querySelector(this.replyFormSelector)) {
            this.loadFormData();
            this.initFormEvents();
        }
    }

    // Init after frameworks
    initAfterFrameworks() {
        var self = this;

        // Tooltip on overflowing cells
        $(document).on('mouseenter', self.tableSelector + ' td', function () {
            if (Foundation.MediaQuery.is('large')) {
                var $this = $(this);

                if (this.offsetWidth - this.scrollWidth <= 0 && !$this.attr('title')) {
                    $this.attr('title', $this.text());
                }
            }
        });

        // Resizeable columns
        $(self.tableSelector).resizableColumns();
    }

    initTableEvents() {
        var self = this;
        
        // Teacher Name Search
        if (self.inputTeacherName) {
            $(self.inputTeacherName).on('keypress', function (e) {
                if (e.which == 13) {
                    e.preventDefault();

                    self.loadTable();

                    return false;
                }
            });
        }

        if (self.btnTeacherName) {
            $(self.btnTeacherName).on('click', function (e) {
                self.loadTable();
            });
        }

        // Status dropdown
        if (self.ddStatus) {
            $(self.ddStatus).on('change', function (e) {
                self.loadTable();
            });
        }

        // Sort dropdown (teacher name)
        if (self.ddSort) {
            $(self.ddSort).on('change', function (e) {
                self.inputOrder.value = self.ddSort.value;
                self.inputOrderBy.value = 'teachername';
                self.loadTable();
            });
        }

        // Table sort headers
        $(self.tableSortHeaderSelector).on('click', function (e) {
            this.dataset.sortValue = this.dataset.sortValue === 'asc' ? 'desc' : 'asc';
            $(self.tableSortHeaderSelector).removeClass('active');
            $(this).addClass('active');

            self.inputOrderBy.value = this.dataset.sortBy;
            self.inputOrder.value = this.dataset.sortValue;
            self.loadTable();
        });

        // Pager
        $(self.pager).on('click', '[data-page]', function (e) {
            if (!this.classList.contains('disabled')) {
                self.params.currentPage = parseInt(this.dataset.page);
                self.loadTable();
            }
        });

        // Update items per page
        $(self.pager).on('change', '#messagehub-input-items-per-page', function (e) {
            self.params.currentPage = 1;
            self.loadTable();
        });

        // Update current page by input
        $(self.pager).on('keypress', '#messagehub-input-page-number', function (e) {
            if (e.which == 13) {
                e.preventDefault();

                self.loadTable();

                return false;
            }
        });

        $(self.pager).on('blur', '#messagehub-input-page-number', function (e) {
            var inputCurrentPage = $(self.pager).find("#messagehub-input-page-number").val();

            if (inputCurrentPage) {
                if (inputCurrentPage >= 1 && inputCurrentPage <= self.pagesTotal) {
                    self.params.currentPage = inputCurrentPage;
                } else {
                    self.params.currentPage = 1;
                }
            } else {
                self.params.currentPage = 1;
            }
            
            self.loadTable();

            return false;
        });
    }

    loadTable() {
        var self = this;
        var selectTotalPageItems = $(self.pager).find("#messagehub-input-items-per-page");
        
        if (self.inputTeacherName) {
            self.params.teacherName = self.inputTeacherName.value;
        }

        if (self.ddStatus) {
            self.params.status = self.ddStatus.value;
        } else {
            self.params.status = 'All';
        }

        if (selectTotalPageItems.val()) {
            self.params.pageSize = parseInt(selectTotalPageItems.val());
        } else {
            self.params.pageSize = 10;
        }

        self.params.orderBy = self.inputOrderBy.value;
        self.params.sort = self.inputOrder.value === 'asc' ? 'A' : 'D';
        self.params.type = self.inputType.value;

        self.showLoader();

        $.ajax({
            type: 'GET',
            url: '/api/hubapi/GetPageMessages',
            data: self.params,
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',

            success: function (responseData, textStatus, jqXHR) {

                if (responseData.Success == true && responseData.Content && responseData.Content.ListTeacherMessages) {
                    var html = '';

                    if (responseData.Content.ListTeacherMessages.length > 0) {
                        var prevTeacherNumber = 0; // to visually separate teacher rows
                        var isPrevAccented = false;

                        responseData.Content.ListTeacherMessages.forEach(function (item) {
                            var receivedOn = new Date(item.ReceivedOn);
                            receivedOn = receivedOn.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });

                            var repliedOn = '';

                            if (item.RepliedOn) {
                                var dateRepliedOn = new Date(item.RepliedOn);
                                repliedOn = dateRepliedOn.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
                            }

                            if (window.messageHubSettings.viewType === 'teacher') {
                                html += `<tr>
                                      <td class="tps-messagehub-table-cell-firstname" data-header="${window.messageHubSettings.translations.table.firstName}">${item.FirstName}</td>
                                      <td class="tps-messagehub-table-cell-lastname" data-header="${window.messageHubSettings.translations.table.lastName}">${item.LastName}</td>
                                      <td class="tps-messagehub-table-cell-email" data-header="${window.messageHubSettings.translations.table.email}">${item.Status === 'Completed' ? item.UserEmail : ''}</td>
                                      <td data-header="${window.messageHubSettings.translations.table.lessonsFor}">${item.LessonsFor}</td>
                                      <td data-header="${window.messageHubSettings.translations.table.level}">${item.Level}</td>
                                      <td data-header="${window.messageHubSettings.translations.table.instrument}">${item.Instrument}</td>
                                      <td data-header="${window.messageHubSettings.translations.table.receivedOn}">${receivedOn}</td>
                                      <td class="tps-messagehub-table-cell-status ${item.Status.toLowerCase()}" data-header="${window.messageHubSettings.translations.table.status}"><span>${item.Status === 'Completed' ? 'Replied' : item.Status}</span></td>
                                      <td class="tps-messagehub-table-cell-action"><a href="${window.messageHubSettings.userReplyLink + '?from=teacher&item=' + item.ItemID}">${window.messageHubSettings.translations.table.reply}</a></td>
                                    </tr>`;
                            } else {
                                var isAccented = self.params.orderBy === 'teachername' && ((isPrevAccented && item.TeacherNumber === prevTeacherNumber) || (item.TeacherNumber !== prevTeacherNumber && !isPrevAccented)) ;
                                html += `<tr class="${isAccented ? "accented" : ""}">
                                      <td class="tps-messagehub-table-cell-teachername" data-header="${window.messageHubSettings.translations.table.teacherName}">${item.Teacherfullname}</td>
                                      ${window.messageHubSettings.viewType === 'school' ? '' : `<td data-header="${window.messageHubSettings.translations.table.teacherEmail}">${item.TeacherEmail}</td>`}
                                      <td class="tps-messagehub-table-cell-firstname" data-header="${window.messageHubSettings.translations.table.firstName}">${item.FirstName}</td>
                                      <td class="tps-messagehub-table-cell-lastname" data-header="${window.messageHubSettings.translations.table.lastName}">${item.LastName}</td>
                                      <td class="tps-messagehub-table-cell-email" data-header="${window.messageHubSettings.translations.table.email}">${item.UserEmail}</td>
                                      <td data-header="${window.messageHubSettings.translations.table.lessonsFor}">${item.LessonsFor}</td>
                                      <td data-header="${window.messageHubSettings.translations.table.level}">${item.Level}</td>
                                      <td data-header="${window.messageHubSettings.translations.table.instrument}">${item.Instrument}</td>
                                      ${window.messageHubSettings.viewType === 'school' ? '' : `<td data-header="${window.messageHubSettings.translations.table.school}">${item.School}</td>`}
                                      <td data-header="${window.messageHubSettings.translations.table.receivedOn}">${receivedOn}</td>
                                      <td data-header="${window.messageHubSettings.translations.table.repliedOn}">${repliedOn}</td>
                                      <td class="tps-messagehub-table-cell-status ${item.Status.toLowerCase()}" data-header="${window.messageHubSettings.translations.table.status}"><span>${item.Status === 'Completed' ? 'Replied' : item.Status}</span></td>
                                      <td class="tps-messagehub-table-cell-action"><a href="${window.messageHubSettings.userReplyLink + '?from=' + window.messageHubSettings.viewType + '&item=' + item.ItemID}">${window.messageHubSettings.translations.table.reply}</a><a href="${window.messageHubSettings.userReplyLink + '?from=' + window.messageHubSettings.viewType + '&to=teacher&item=' + item.ItemID}">${window.messageHubSettings.translations.table.contactTeacher}</a></td>
                                    </tr>`;
                            }

                            prevTeacherNumber = item.TeacherNumber;
                            isPrevAccented = isAccented;
                        });

                    } else {
                        var columnsCount = $(self.tableSelector + ' thead th').length;
                        html = '<tr><td colspan="' + columnsCount + '">No records found</td></tr>';
                    }

                    self.pagesTotal = responseData.Content.TotalPages;

                    self.renderPager();

                    $(self.tableSelector + ' tbody').html(html);
                }

                // Hide page loader
                self.hideLoader();
            },

            error: function (responseData, textStatus, errorThrown) {
                self.hideLoader();
            }
        });
    }

    loadFormData() {

    }

    initFormEvents() {
        var self = this;

        if (self.replyFormInputMessage && self.replyFormInputConsent && self.replyFormBtnSend) {
            $(self.replyFormInputMessage).add($(self.replyFormInputConsent)).on('change_custom', function (e) {
                var field = $(this);

                field.parent().removeClass('Error');
                field.siblings('.EditingFormErrorLabel').remove();

                if ((field.prop('type') === 'checkbox' && !field.is(':checked')) || $.trim(field.val()).length === 0) {
                    field.parent().addClass('Error');
                    field.parent().append('<span class="EditingFormErrorLabel">This field is required</span>');
                } 
            });

            $(self.replyFormTOUDecline).on('click', function (e) {
                e.preventDefault();

                $(self.replyFormTOUReveal).foundation('close');

                self.showFeedback('You need to agree with the Terms of Use to reply to this message', 'warning');
            });

            $(self.replyFormTOUAgree).on('click', function (e) {
                e.preventDefault();
                $(self.replyFormTOUReveal).foundation('close');
                self.sendReplyToWS();
            })

            $(self.replyFormBtnSend).on('click', function (e) {
                e.preventDefault();

                self.hideFeedback();

                $(self.replyFormInputMessage).trigger('change_custom');
                $(self.replyFormInputConsent).trigger('change_custom');

                if ($(self.replyFormInputMessage).parent().hasClass('Error') || $(self.replyFormInputConsent).parent().hasClass('Error')) {
                    return false;
                }

                if (window.messageHubSettings.replyFormView === 'admin' || window.messageHubSettings.replyFormView === 'school') {
                    self.sendReplyToWS();
                } else {
                    $(self.replyFormTOUReveal).foundation('open');
                }

                return false;
            });
        }
    }

    sendReplyToWS() {
        var self = this;

        var wsUrl = window.messageHubSettings.replyFormAdressee === 'teacher' ? '/api/hubapi/PostReplyToTeacher' : '/api/hubapi/PostReplyToUser';

        var params = {
            ItemID: self.replyFormInputItemID.value,
            msgText: self.replyFormInputMessage.value
        };

        this.replyFormBtnSend.disabled = true;
        self.showLoader();

        $.ajax({
            type: 'POST',
            url: wsUrl,
            data: JSON.stringify(params),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',

            success: function (responseData, textStatus, jqXHR) {

                if (responseData.Success === true) {
                    self.showFeedback(responseData.Content, 'success');
                } else {
                    self.showFeedback('Error', 'alert');
                    this.replyFormBtnSend.disabled = false;
                }

                // Hide page loader
                self.hideLoader();
            },

            error: function (responseData, textStatus, errorThrown) {
                self.showFeedback('Error', 'alert');
                self.hideLoader();
                this.replyFormBtnSend.disabled = false;
            }
        });
    }

    renderPager() {
        var self = this;
        var currentPage = self.params.currentPage;
        var totalPages = self.pagesTotal;
        var pageSize = self.params.pageSize;

        if (self.pagesTotal >= 0) {
            var html = `
                <div class="tps-messagehub-table-pagination-pages_action">
                    <a class="pager-navs pager-prev ${currentPage == 1 ? 'disabled' : ''}" href="#" title="Go to the previous page of results" data-page="${currentPage - 1}" aria-label="Previous Page"></a>
                    <label for="messagehub-input-page-number">Page</label>
                    <input type="number" id="messagehub-input-page-number" name="messagehub-input-page-number" min="1" max="${totalPages}" step="1" value="${currentPage}">
                    <span>/${totalPages}</span>
                    <a class="pager-navs pager-next ${currentPage == self.pagesTotal ? 'disabled' : ''}" href="#" title="Go to the next page of results" data-page="${currentPage + 1}" aria-label="Next Page"></a>
                </div>

                <div class="tps-messagehub-table-pagination-items_per_page">
                    <label for="messagehub-input-items-per-page">Items per page</label>
                    <select name="messagehub-input-items-per-page" id="messagehub-input-items-per-page">
                        <option value="10"${pageSize  == 10  ? ' selected' : ''}>10</option>
                        <option value="25"${pageSize  == 25  ? ' selected' : ''}>25</option>
                        <option value="50"${pageSize  == 50  ? ' selected' : ''}>50</option>
                        <option value="100"${pageSize == 100 ? ' selected' : ''}>100</option>
                        <option value="0"${pageSize   == 0   ? ' selected' : ''}>All</option>
                    </select>
                </div>
            `;

            self.pager.innerHTML = html;

            self.pager.classList.remove('hide');

        } else {
            self.pager.classList.add('hide');
        }

        /*
        if (self.pagesTotal >= 0) {
            // <a class="pager-navs pager-first ${currentPage === 1 ? 'disabled' : ''}" href="#" title="Go to the first page of results" data-page="1">First</a>
            var html = `
                <a class="pager-navs pager-prev ${currentPage === 1 ? 'disabled' : ''}" href="#" title="Go to the previous page of results" data-page="${currentPage - 1}">Previous</a>
                <div class="pager-nav-items">
            `;

            for (var i = 1; i <= self.pagesTotal; i++) {
                if (i === currentPage) {
                    html += `<span title="Your are on page ${currentPage}">${currentPage}</span>`;
                } else {
                    html += `<a href="#" title="Go to page ${i}" data-page="${i}">${i}</a>`;
                }
            }

            html += `
                </div>
                <a class="pager-navs pager-next ${currentPage === self.pagesTotal ? 'disabled' : ''}" href="#" title="Go to the next page of results" data-page="${currentPage + 1}">Next</a>
            `;
            //<a class="pager-navs pager-last ${currentPage === self.pagesTotal ? 'disabled' : ''}" href="#" title="Go to the last page of results" data-page="${self.pagesTotal}">Last</a>

            self.pager.innerHTML = html;

            self.pager.classList.remove('hide');
        } else {
            self.pager.classList.add('hide');
        }
        */
    }

    showLoader() {
        $(this.loader).stop(true, false).fadeIn(250, function () {
            $(this).css('display', 'flex');
        });
    }

    hideLoader() {
        $(this.loader).stop(true, false).fadeOut(250);
    }

    showFeedback(message, calloutClass) {
        $(this.replyFormFeedback).html(message).addClass(calloutClass).removeClass('hide');
    }

    hideFeedback() {
        $(this.replyFormFeedback).html('').attr('class', 'tps-messagehub-replyform-feedback hide');
    }
}

TPS.MessageHub = new MessageHub();
