'use strict';

class TPSUtility {
    // Constructor
    constructor() {
        this.window = $(window);
        this.document = $(document);
    }

    // Init before frameworks
    init() {


        // listen for any anchor links
        this.document.on('click', 'a[href^="#"]:not([href="#"]):not([href^="#tps-workshop-calendar-day"])', function (e) {
            e.preventDefault();
            let _hash = this.hash;
            let isZfPlugin = $(this).attr('role') && $(this).attr('role') === 'tab';
            if (!isZfPlugin)
                TPS.Utility.smoothScroll(_hash);
        });

        this.wrapTables();


    }

    // Init after frameworks
    initAfterFrameworks() {
        let _self = TPS.Utility;
        // smooth scroll on load
        if (location.hash) {
            
            let _hash = location.hash;
            let targetElem = $(_hash);
            // check if we are scrolling to a tab / accordion
            if (targetElem.length > 0 && targetElem.attr('role') === 'tabpanel') {

                let _interval = setInterval(function () {

                    if (targetElem.is(':visible')) {
                        _self.smoothScroll(_hash);
                        clearInterval(_interval);
                    }

                }, 250);
                
            } else {
                // smooth scroll to hash
                _self.smoothScroll(_hash);
            }

            //

            
        }
    }

    wrapTables() {

        $('.accordion-content-inner table, .tabs-panel table, .tps-editable-text table, .tps-form-container table').each(function () {
            $(this).wrap('<div class="table-scroll" />');
        });
    }

    smoothScroll(selector) {
        var _target = null;
        var _id_target = $(selector);
        var _name_target = typeof selector.replace === "function" ? $('[name=\'' + selector.replace('#', '') + '\']') : [];
        //var $headerOffest = $('#header').outerHeight() + 175;
        
        // are we targetting an ID anchor?    
        if (_id_target.length > 0) {
            _target = _id_target;
        }
        // are we targetting an name anchor?
        else if (_name_target.length > 0) {
            _target = _name_target;
        }
        
        // if the anchor exists, scroll to its location
        if (_target) {

            let bodyOffset = $('body').hasClass('sticky-header') ? 0 : 90;
            let headerOffset = $('.tps-header').outerHeight();
            let tabAccOffset = 60;
            let scrollOffset = bodyOffset + headerOffset + tabAccOffset;
            let targetScrollTop = _target.offset().top - scrollOffset;

            $('html, body').animate(
                {
                    scrollTop: targetScrollTop
                },
                500
            ).promise().done(function () {
                // Set focus on element
                let focusElem = _target;
                // check if we are scrolling to tab/accordion
                // and change focus elem to the tab or accordion ctrl
                // instead of tab / acccordion content
                if (_target.attr('role') === 'tabpanel') {
                    focusElem = $('#' + _target.attr('aria-labelledby'));
                }
                // Adding tabindex for elements not focusable
                if (!focusElem.attr('tabindex')) {
                    focusElem.attr('tabindex', '-1'); 
                }
                // Set focus on scrolled elem;
                focusElem.focus();
            });
            
        }

    }

    // set cookie value
    setCookie(cname, cvalue, cexpiration) {
        if (!cexpiration) {
            cexpiration = 365;
        }
        var d = new Date();
        d.setTime(d.getTime() + (cexpiration * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    // get cookie value
    getCookie(cname) {
        if (cname) {
            var name = cname + '=';
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return false;
        } else {
            return false;
        }
    }

    // set local or session storage
    setStorage(key, val, local) {
        if (local) {
            localStorage.setItem(key, val);
        } else {
            sessionStorage.setItem(key, val);
        }
    }

    // get local or session storage
    getStorage(key, local) {

        if (key) {
            var val;
            if (local) {
                val = localStorage.getItem(key);
            } else {
                val = sessionStorage.getItem(key);
            }
            //  check to see if storage item exists
            if (val) {
                return val;
            } else {
                return false;
            }

        } else {
            return false;
        }
    }

    clearStorage(key, local) {
        if (key) {
            if (local) {
                localStorage.removeItem(key);
            } else {
                sessionStorage.removeItem(key);
            }
        }
    }

    updateUrlParameter(uri, key, value) {
        // remove the hash part before operating on the uri
        var i = uri.indexOf('#');
        var hash = i === -1 ? '' : uri.substr(i);
        uri = i === -1 ? uri : uri.substr(0, i);

        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";

        if (!value) {
            // remove key-value pair if value is empty
            uri = uri.replace(new RegExp("([?&]?)" + key + "=[^&]*", "i"), '');
            if (uri.slice(-1) === '?') {
                uri = uri.slice(0, -1);
            }
            // replace first occurrence of & by ? if no ? is present
            if (uri.indexOf('?') === -1) {
                uri = uri.replace(/&/, '?')
            }
        } else if (uri.match(re)) {
            uri = uri.replace(re, '$1' + key + "=" + value + '$2');
        } else {
            uri = uri + separator + key + "=" + value;
        }
        return uri + hash;
}

    getURLParamString() {
        return window.location.search.substring(1);
    }

    getURLParameter(sParam) {
        var sURLVariables = this.getURLParamString().split('&');
        var paramVal = false;
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] == sParam) {
                paramVal = sParameterName[1];
                break;
            }
        }
        return paramVal;
    }

    clearFormFields(fw) {
        $(fw).find(':input').each(function (k, v) {
            if ($(v).is(':radio') || $(v).is(':checkbox')) {
                $(this).prop('checked', false);
            } else if ($(v).attr('type') != 'hidden') {
                $(v).val('');
            }
        });        
    }

    webserviceLoaded(namespace) {
        var tokens = namespace.split('.');
        return tokens.reduce(function (prev, curr) {
            return (typeof prev == "undefined") ? prev : prev[curr];
        }, window);
    }

    loadWebserviceJS(namespace) {
        if (!TPS.Utility.webserviceLoaded(namespace)) {
            var webserviceName = namespace.replace('CMSApp.', '').replace(/\./g, '/');
            jQuery.ajax({
                url: '/' + webserviceName + '.asmx/js',
                dataType: 'script',
                async: false
            });
        }
    }

}

TPS.Utility = new TPSUtility();
