'use strict';

class TPSAccordion {
    // Constructor
    constructor() {
        this.window = $(window);
        this.document = $(document);
    }

    // Init before frameworks
    init() { }

    // Init after frameworks
    initAfterFrameworks() {

        // listen for when accordions are closed
        // and if all accordions are closed, remove
        // the deep link hash from the URL
        this.document.on('up.zf.accordion', '[data-accordion]', TPS.Accordion.checkHash);

    }

    checkHash(e) {

        if ($(e.target).find('.is-active[data-accordion-item]').length === 0) {
            let url = window.location.href.substr(0, window.location.href.indexOf('#'));
            if ($(e.target).attr('data-data-update-history') && $(e.target).attr('data-data-update-history').toLowerCase() === 'true') {
                window.history.pushState({}, '', url);
            } else {
                window.history.replaceState({}, '', url);
            }            
        }
    }
};

TPS.Accordion = new TPSAccordion();
