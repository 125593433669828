'use strict';

class TPSForm {
    // Constructor

    constructor() {
        this.window = $(window);
        this.document = $(document);
    }

    // Init before frameworks
    init() {
        this.checkSelectInputs();
        this.checkFileInputs();
        this.checkCanvasInputs();
    }

    // Init after frameworks
    initAfterFrameworks() {

    }

    // add selected file text next to label (for styling purposes)
    // add listener to file text to clear input
    checkFileInputs() {
        if ($('[type="file"]').length > 0) {
            this.document.on('change', '[type="file"]', function (e) {
                let forTarget = $(this).attr('id');                
                let selectedVal = $(this).val();
                let currentValCtl = $('#' + forTarget + '_tpsFileValue');

                if (currentValCtl.length > 0) {
                    currentValCtl.remove();
                }

                if (selectedVal != '' && selectedVal != null) {
                    let inputText = selectedVal.substring($(this).val().lastIndexOf('\\') + 1);
                    $('[for="' + forTarget + '"]').after($('<a href="#"/>').addClass('tps-file-value').attr('id', forTarget + '_tpsFileValue').text(inputText));
                }
            });

            this.document.on('click', '.tps-file-value', function (e) {
                e.preventDefault();
                let input = $('#' + $(this).attr('id').replace('_tpsFileValue', ''));
                $(this).remove();
                input.val('');
            });
        }
    }

    checkCanvasInputs() {
        if ($('.tps-canvas-input-wrapper').length > 0) {
            $('.tps-canvas-input-wrapper').each(function () {
                //console.log('foo!');
                //TPS.Form.updateSelectInputClass($(this));
                let _targetInput = $(this).find('.tps-canvas-input');
                let _signature = $(this).find('.tps-canvas-input-canvas');
                let _reset = $(this).find('.tps-canvas-input-reset');
                /*
                */
                let settings = {
                    'sizeRatio': 2,
                    'color': '#191919',
                    'decor-color': '#a4a4a4',
                    'lineWidth': 0,
                    'minFatFingerCompensation': -10      
                };

                _signature.jSignature(settings);

                if (_targetInput.val()) {
                    _signature.jSignature('setData', _targetInput.val());
                }


                _signature.on('change', function (e) {                    
                    //console.log($(this));
                    //console.log(targetInput);
                    var val = $(this).jSignature("getData");
                    _targetInput.val(val);
                });

                _reset.on('click', function (e) {
                    e.preventDefault();
                    _signature.jSignature('reset');
                    _targetInput.val('');
                });

            });
        }
    }

    checkSelectInputs() {
        if ($('select').length > 0) {
            $('select').each(function () {
                TPS.Form.updateSelectInputClass($(this));
            });

            this.document.on('change', 'select', function (e) {
                TPS.Form.updateSelectInputClass($(this));
            });
        }
    }

    updateSelectInputClass(elem) {
        if (elem.val() != '' && elem.val() != '-1') {
            elem.addClass('selected');
        } else {
            elem.removeClass('selected');
        }
    }


}

TPS.Form = new TPSForm();
