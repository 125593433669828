'use strict';

class TPSFormValidate {

    // Constructor

    constructor() {
        this.window = $(window);
        this.document = $(document);
        this.fieldPrefix = null;
        this.requiredFieldPrefix = null;
        this.emailFieldSelector = null;
        this.phoneFieldSelector = null;
        this.dateFieldSelector = null;
        this.zipcodeFieldSelector = null;
    }

	// Init
	init() {
		// If there is no Form Widget on the page
		if (0 == jQuery('.form-container').length) {
			return false;
		}

		this.setVariables();
		this.addMasks();
        this.addValidations();
        // do we have a per field limit configured?
        if (0 < jQuery('.form-container[data-form-limit="byfield"]').length)
            this.addLimitValidation();
    }

    // Init after frameworks
    initAfterFrameworks() { }


	// Set variables
	setVariables() {
		this.fieldPrefix = '.form-control';
		this.requiredFieldSulfix = ':not(.tps-not-required)';
		this.requiredFieldPrefix = this.fieldPrefix + this.requiredFieldSulfix;

		this.emailFieldSelector = this.fieldPrefix + '[name*="$Email$"],' +
								  this.fieldPrefix + '[name*="$emailinput$"],' +
								  this.fieldPrefix + '[name*="$EmailAddress$"]';

		this.phoneFieldSelector = this.fieldPrefix + '[name*="$PhoneNumber$"],' +
								  this.fieldPrefix + '[name*="$FaxNumber$"],' +
								  this.fieldPrefix + '[name*="$HomePhone$"],' +
								  this.fieldPrefix + '[name*="$WorkPhone$"],' +
								  this.fieldPrefix + '[name*="$CellPhone$"]',

		this.zipcodeFieldSelector = this.fieldPrefix + '[name*="$PostalCode$"]';
    };

	// Add form masks
	addMasks() {
		// If jQuery.fn.mask is not defined
		if (! jQuery.fn.mask) {
			console.log('jQuery.fn.mask not defined');
			return false;
		}

		// Phone (US, CA) mask
		jQuery(this.phoneFieldSelector).mask('999-999-9999');

		// Date (YYYY-MM-DD) mask
		jQuery(this.dateFieldSelector).mask('9999-99-99');

		// Zipcode mask
		jQuery(this.zipcodeFieldSelector).mask('a9a 9a9');
    };

	// Add form validations
	addValidations() {

        jQuery('.tps-has-limit').each(function () {
            var _this = jQuery(this);
            if (_this.hasClass('radio') || _this.hasClass('checkbox')) {
                _this.find('input').addClass('tps-has-limit');
            }
        });

        jQuery('.tps-not-required').each(function () {
            var _this = jQuery(this);
            if (_this.find('input.hasDatepicker').length !== 0){
                _this.find('input').addClass('tps-not-required');
			}
            if (_this.hasClass('checkbox') || _this.hasClass('radio')){
				_this.find('input').addClass('tps-not-required');
            }

            if (_this.find('input.uploader-input-file').length !== 0) {
                _this.find('input[type="file"]').addClass('tps-not-required');
            }

		});

		// If jQuery.fn.tpsValidate is not defined
		if (! jQuery.fn.tpsValidate) {
			console.log('jQuery.fn.tpsValidate not defined');
			return false;
		}

		// Run the TPS validate plugin on the main Kentico form
		var kenticoForm = jQuery('#form');

		kenticoForm.tpsValidate({
			// Email validation
			emailFieldSelector: this.emailFieldSelector,
			emailFieldMessage: 'Please enter a valid email address',

			// Phone (US, CA) validation
			phoneFieldSelector: this.phoneFieldSelector,
			phoneFieldMessage: 'Please enter a valid phone number',

			// Date (YYYY-MM-DD) validation
			dateFieldSelector: this.dateFieldSelector,
			dateFieldMessage: 'Please enter a valid date',

			// Zipcode (CA) validation
			zipcodeFieldSelector: this.zipcodeFieldSelector,
			zipcodeFieldMessage: 'Please enter a valid postal code',

			// Required validation
            requiredFieldSelector: this.requiredFieldPrefix + ',' +
                                    '.uploader-input-file' + this.requiredFieldSulfix + ',' + 
								   'input[type="radio"]' + this.requiredFieldSulfix + ',' +
								   'input[type="checkbox"]' + this.requiredFieldSulfix,

			// Required custom validation
			requiredFieldsCustomMessages: [
				// E-mail
				{
					selector: this.requiredFieldPrefix + '[name*="$Email$"],' +
							  this.requiredFieldPrefix + '[name*="$emailinput$"],' +
							  this.requiredFieldPrefix + '[name*="$EmailAddress$"]',
					message: 'Please enter a valid email address'
				},

				// Phone
				{
					selector: this.requiredFieldPrefix + '[name*="$PhoneNumber$"],' +
							  this.requiredFieldPrefix + '[name*="$FaxNumber$"],' +
							  this.requiredFieldPrefix + '[name*="$HomePhone$"],' +
							  this.requiredFieldPrefix + '[name*="$WorkPhone$"],' +
							  this.requiredFieldPrefix + '[name*="$CellPhone$"]',
					message: 'Please enter a valid phone number'
				},


				// Zipcode
				{
					selector: this.requiredFieldPrefix + '[name*="$PostalCode$"]',
					message: 'Please enter a valid zipcode'
				}

			]
		});
    }

    addLimitValidation() {
        var _this = TPS.FormValidate;
        // 
        TPS.Utility.loadWebserviceJS('CMSApp.RCM.API.FormValidation'); //ValidateCap

        jQuery(document).on('change', ':input.tps-has-limit', function (e) {

            var _input, _inputWrapper, _inputName_arr, _inputName, _wrapper, _formId, _formActions, _message;

            _input = $(this);
            
            _wrapper = _input.parents('.form-container');
            _formActions = _wrapper.find('.tps-form-controls .button');
            _formActions.addClass('disabled').prop('disabled', true);
            _inputWrapper = _input.parents('.tps-form-question');
            // remove any limit message that may already be applied
            _inputWrapper.find('.tps-form-limit-message').remove();
            
            _formId = _wrapper.data('formId');
            // kentico input names have a pattern like:
            //
            //      p$lt$ctl03$pageplaceholder$p$lt$ctl01$TPSWP_Bizform$viewBiz$SelectBox$dropDownList
            //
            // so we split by the $ char, and then get the second last
            // value to get the field name
            _inputName_arr = _input.attr('name').split('$');
            _inputName = _inputName_arr[_inputName_arr.length - 2];

            CMSApp.RCM.API.FormValidation.ValidateCap(_formId, _inputName, _input.val(), function (_optionAvailable) {
                // if the limit for the selected options
                // has been reached:
                if (!_optionAvailable) {
                    _message = window.formLimitMessages[_formId].message;
                    _inputWrapper.append('<div class=\"tps-form-limit-message\">' + _message + '</div>');
                    
                } else {
                    _formActions.removeClass('disabled').prop('disabled', false);
                }

            }, function (err) {
                _message = '<p><strong>Error ' + err._statusCode + '</strong><br/>' + err._message + '</p>';

                _inputWrapper.append('<div class=\"tps-form-limit-message\">' + _message + '</div>');

                console.log(err);
            });
                        
        });
    }

}

TPS.FormValidate = new TPSFormValidate();
