'use strict';

class NavMobile {
    // Constructor
    constructor() {
        this.window = $(window);
        this.document = $(document);
    }

    // Init before frameworks
    init() {
        this.MobileMenuFlyout = $('#tps-mobile-navigation-flyout');
    }

    // Init after frameworks
    initAfterFrameworks() {

        $('.tps-navigation-main-mobile-toggle').on('click', function (e) {
            TPS.NavMobile.MobileMenuFlyout.find('[data-off-canvas]').each(function () {
                $(this).foundation('close');
            });
        });
        
    }
};

TPS.NavMobile = new NavMobile();
