'use strict';

class TabletSorter {
    // Constructor
    constructor() {
        this.window = $(window);
        this.document = $(document);
    }

    // Init before frameworks
    init() {
        this.tables = $('.tps-table-sorter');
    }

    // Init after frameworks
    initAfterFrameworks() {
        let _this = this;

        if (_this.tables) {
            _this.tables.each(function () {
                // Documentation: https://mottie.github.io/tablesorter/docs/example-options-headers.html
                $(this).tablesorter();
            });
        }
    }
};

TPS.TabletSorter = new TabletSorter();
