'use strict';

class ActionFlag {
    // Constructor
    constructor() {
        this.window = $(window);
        this.document = $(document);
    }

    // Init before frameworks
    init() {

        let _this = TPS.ActionFlag;

        _this.cta = $('.tps-action-flag-wrapper');
        _this.contentBody = $('.tps-body');
        _this.footerNode = $('#footer');
       
        if (_this.cta.length > 0) {

            _this.contentBody.addClass('has-tps-action-flag');

            _this.actionFlagUpdatePosition();

            _this.window.scroll(function () {
                TPS.ActionFlag.onScroll();
            }).scroll();

            _this.cta.on('close.zf.trigger', function () {
                _this.contentBody.removeClass('has-tps-action-flag');
            });
        }
    }

    // Init after frameworks
    initAfterFrameworks() { }    

    // On scroll
    onScroll() {
        let _this = TPS.ActionFlag;
        _this.requestTick();
    }

    // On scroll (request tick // to avoid overload window.onscroll)
    requestTick() {
        let _this = TPS.ActionFlag;
        if (!_this.ticking) {
            (window.requestAnimationFrame
                || window.webkitRequestAnimationFrame
                || window.mozRequestAnimationFrame
                || window.msRequestAnimationFrame
                || window.oRequestAnimationFrame)(_this.actionFlagUpdatePosition);

            _this.ticking = true;
        }
    }

    // Back to top (update node position)
    actionFlagUpdatePosition() {
        let _this = TPS.ActionFlag;
        let scrollTop = _this.window.scrollTop();

        if (scrollTop > 0) {
            if (!_this.cta.hasClass('show')) {
                _this.cta.addClass('show');
                _this.cta;
            }

            if (_this.footerNode.position().top - scrollTop < _this.window.height()) {
                _this.cta.addClass('fixed');
            } else {
                _this.cta.removeClass('fixed');
            }
        } else {
            _this.cta.removeClass('show');
        }

        _this.ticking = false;
    }
};

TPS.ActionFlag = new ActionFlag();
