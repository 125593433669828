'use strict';

class GGSHomepageSlider {
    // Constructor

    constructor() {
        this.window = $(window);
        this.document = $(document);
    }

    // Init before frameworks
    init() {
        this.slider = $('.ggs-slider-wrapper');
    }

    // Init after frameworks
    initAfterFrameworks() {
        let _this = this;

        if (_this.slider.length > 0) {
            let sliderOptions = {
                infinite: true,
                arrows: false,
                dots: true,
                dotsClass: 'ggs-slider-dots',
            };

            this.slider.each(function () {
                $(this).slick(sliderOptions);
            });
        }
    }
};

TPS.GGSHomepageSlider = new GGSHomepageSlider();
