'use strict';

class ImageGallery {
    // Constructor

    constructor() {
        this.window = $(window);
        this.document = $(document);
    }

    // Init before frameworks
    init() {
        this.carouselGallery = $('.tps-slider');
        //this.galleryItem = $('.');
    }

    // Init after frameworks
    initAfterFrameworks() {

        let _this = this;

        if (_this.carouselGallery) {

            let caourselGalleryOptions = {
                slidesToShow: 3,
                slidesToScroll: 3,
                autoplay: false,
                autoplaySpeed: 2000,
                centerMode: true,
                centerPadding: 0,
                //variableWidth: true,
                adaptiveHeight: false,

                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            centerPadding: '25%',
                            slidesToShow: 1,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 520,
                        settings: {
                            centerPadding: 0,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]

            };

            this.carouselGallery.each(function () {
                $(this).slick(caourselGalleryOptions);
            });
        }

        _this.document.on('click', '.tps-gallery-item', function (e) {
            e.preventDefault();
            var targetObject = $(this).data('galleryArrayId');
            var itemId = $(this).data('itemId');
            var itemIndex = $(this).data('itemIndex');                        
            TPS.ImageGallery.openGalleryModal(window[targetObject], targetObject, itemIndex, itemId);
        });

        _this.document.on('click', '.tps-gallery-modal-control', function (e) {
            e.preventDefault();
            let targetObjectId = $(this).data('galleryArrayId');
            let targetobject = window[targetObjectId];
            let targetItemIndex = $(this).data('targetItemIndex');
            let modalId = $(this).parents('.gallery-modal').attr('id');
            _this.galleryModalControls(modalId, targetobject, targetObjectId, targetItemIndex);
        });


    }

    galleryModalControls(modalId, targetObject, targetItemId, targetItemIndex) {
        $('#' + modalId).empty().append(TPS.ImageGallery.createGalleryModalContent(targetObject, targetItemId, targetItemIndex));        
    }

    openGalleryModal(galleryObj, galleryId, itemIndex, itemId) {

        let options, elem, modal;
        let _this = this;
        let additionalClasses = galleryObj[itemIndex]['className'] ? galleryObj[itemIndex]['className'] : '';       

        options = {
            resetOnClose: true,
            additionalOverlayClasses: 'tps-gallery-modal-overlay',
            appendTo: '#form',
        };

        elem = $('#gallery-modal-' + itemId);
        if (elem.length === 0) {

            elem = $('<div>').attr('id', 'gallery-modal-' + itemId).addClass('reveal gallery-modal ' + additionalClasses);            

            elem.append(_this.createGalleryModalContent(galleryObj, galleryId, itemIndex, itemId));

            modal = new Foundation.Reveal(elem, options);

        } else {
            elem.empty().append(_this.createGalleryModalContent(galleryObj, galleryId, itemIndex, itemId));
        }
        elem.foundation('open');
        

        elem.on('closed.zf.reveal', function () {
            $(document).off('keydown', TPS.ImageGallery.keyboardNavigation);
        });

        $(document).on('keydown', TPS.ImageGallery.keyboardNavigation);
    }

    keyboardNavigation(e) {
        if (e.keyCode === 37) {
            $('.tps-gallery-modal-control[data-gallery-dir="prev"]').trigger('click');
        } else if (e.keyCode === 39) {
            $('.tps-gallery-modal-control[data-gallery-dir="next"]').trigger('click');
        }
    }

    createGalleryModalContent(galleryObj, galleryId, itemIndex) {

        let galleryItem = galleryObj[itemIndex];
        let itemIndexNum = parseInt(itemIndex);

        let modalContent = ' <button class="close-button" data-close type="button"><span class="sr-only">Close video modal</span>&times;</button>';
        modalContent += '<div class="tps-gallery-modal-item-wrapper">';

        if (galleryObj.length > 1) {
            let prevIndex = itemIndexNum - 1;
            if (itemIndexNum == 0) {
                prevIndex = galleryObj.length - 1;
            }

            modalContent += '<a href="#" class="tps-gallery-modal-control" data-gallery-dir="prev" data-gallery-array-id="' + galleryId + '" data-target-item-id="' + galleryObj[prevIndex].itemId + '" data-target-item-index="' + prevIndex + '"><span class="sr-only">previous image<span></a>';
        }

        modalContent += '<img class="tps-gallery-modal-item-image" src="' + galleryItem.itemImage + '" alt="" /> ';

        if (galleryObj.length > 1) {
            let nextIndex = itemIndexNum + 1;
            if (itemIndexNum == galleryObj.length - 1) {
                nextIndex = 0;
            }
            modalContent += '<a href="#" class="tps-gallery-modal-control" data-gallery-dir="next" data-gallery-array-id="' + galleryId + '" data-target-item-id="' + galleryObj[nextIndex].itemId + '" data-target-item-index="' + nextIndex + '"><span class="sr-only">next image<span></a>';
        }

        if (galleryItem.itemDescription) {
            modalContent += '<div class="tps-gallery-modal-item-text">' + galleryItem.itemDescription + '</div>';
        }
        modalContent += '</div>';

        return modalContent;
    }


};

TPS.ImageGallery = new ImageGallery();
