'use strict';

class GlobalAlerts {
    // Constructor
    constructor() {
        this.window = $(window);
        this.document = $(document);
    }

    // Init before frameworks
    init() { }

    // Init after frameworks
    initAfterFrameworks() {

        // listen for when alerts are closed        
        // set a cookie so that the alert does not appear
        // again!
        this.document.on('close.zf.trigger', '#header-notifications', TPS.GlobalAlerts.dismissAlert);

        this.document.on('closed.zf.reveal', '#roadblock-modal', TPS.GlobalAlerts.dismissAlert);

    }

    dismissAlert(e) {        
        let cookies = $(this).data('cookies').split(',');        
        $(cookies).each(function (k, v) {
           TPS.Utility.setCookie(v, 1);
        });

    }
   
};

TPS.GlobalAlerts = new GlobalAlerts();
