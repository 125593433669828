'use strict';

// Global Object
const TPS = TPS || [];

// Initialize all modules
$(document).ready(function() {
    // Call all init
    for (var i in TPS) {
        if ('function' == typeof TPS[i].init) {
            TPS[i].init();
        }
    }

    // If using Foundation as framework
    $(document).foundation();

    // Call all initAfterFrameworks
    for (var i in TPS) {
        if ('function' == typeof TPS[i].initAfterFrameworks) {
            TPS[i].initAfterFrameworks();
        }
    }



});
