'use strict';

class WorkshopCalendar {
    // Constructor
    constructor() {
        this.window = $(window);
        this.document = $(document);

        this.dateFormat = 'd MMMM, yyyy';
        this.startTimeFormat = 'H:mm';
        this.endTimeFormat = 'H:mma';
        this.isRendered = false;
        this.scheduleSelector = '.tps-workshop-schedule';
    }

    // Init after frameworks (foundation)
    initAfterFrameworks() {

        let _this = this;        
        let scheduleElem = $(_this.scheduleSelector);
        let scheduleParentElem = scheduleElem.parent();
        // does schedule element exist?
        if (scheduleElem.length > 0) {
            // is schedule inside a tab
            if (scheduleParentElem.is('.tabs-panel')) {
                // is the tab active?                
                if (scheduleParentElem.is('.is-active')) {
                    _this.renderSchedule();   
                } else {
                    // has the associated tab control been clicked?
                    let targetTab = $('#' + scheduleParentElem.attr('aria-labelledby'));                
                    targetTab.on('click', function () {                        
                        // make sure the schedule and not already rendered
                        if (!_this.isRendered) {
                            _this.renderSchedule();
                        }
                    });                    
                }    
            }
            // is schedule inside a accordion
            else if (scheduleParentElem.is('.accordion-content-inner')) {
                // check if tab is active and render schedule                
                scheduleElem.closest('[data-accordion]').on('down.zf.accordion deeplink.zf.accordion', function () {
                    // make sure the schedule accordion is visible, and not already rendered                    
                    if (scheduleElem.is(':visible') && !_this.isRendered) {                        
                        _this.renderSchedule();
                    }
                });
            }
            // the schedule is NOT in a tab/accordion
            else {
                _this.renderSchedule();    
            }
            
        }

    }

    renderSchedule() {
        let _this = this;

        _this.loadCalendar(_this.scheduleSelector);
        _this.isRendered = true;
    }

    // Load calendar
    loadCalendar(tagSelector) {
        if ($(tagSelector).length == 0) {
            return false;
        }

        var workshopId = $(tagSelector).data('workshop-id'),
        workshopEventURL = 'https://admin.rcmusic.com/workshops/get-sessions/' + workshopId + '?_format=json';

        // localhost test
        // workshopEventURL = '/RCM/assets/events.json';

        $.when(
            // Calendar libraries
            $.getScript('/RCM/assets/js/moment.min.js'),
            $.getScript('/RCM/assets/js/fullcalendar.min.js'),
            // Calendar data from Drupal
            $.getJSON(workshopEventURL)
        ).done(function (jsData1, jsData2, data) {
            data = data[0];
            var days = [];

            // Translate original json to FullCalendar json
            $.each(data.days, function (key, val) {
                var day = {
                    date: val.date,
                    sessions: []
                };

                $.each(val.sessions, function (key2, val2) {
                    var session = {
                        order: val2.order,
                        title: val2.title,
                        start: val2.start,
                        end: val2.end,
                        summary: val2.summary,
                        txtColor: val2.txt_color,
                        bgColor: val2.bg_color,
                        borderColor: val2.border_color,
                    },
                    description = [],
                    timesDiffInSec = (new Date(session.end) - new Date(session.start)) / 1000;

                    // @TODO
                    // if ( '1' == val2.registration_required ) {
                    //     description.push( 'Registration Required' );
                    // }

                    if (val2.presenter) {
                        description.push(val2.presenter);
                        session.presenter = val2.presenter;
                    }

                    // @TODO
                    // if ( val2.location ) {
                    //     description.push( val2.location );
                    // }

                    // @TODO
                    // if ( val2.limited ) {
                    //     description.push( 'Space is limited' );
                    // }

                    // Short sessions - Has less than 30 minutes (1800 seconds)
                    if (timesDiffInSec < 1800) {
                        session.className = 'short-event';
                    }

                    session.description = description.join(', ');
                    day.sessions.push(session);
                });

                days.push(day);
            });

            // Order sessions by start time
            $.each(days, function (key, val) {
                days[key].sessions.sort(function (x, y) {
                    return new Date(x.start) - new Date(y.start);
                });
            });

            // Set the order attribute to follow the new order
            $.each(days, function (key, val) {
                $.each(days[key].sessions, function (key2, val2) {
                    days[key].sessions[key2].order = key2;
                });
            });

            // Tab container
            $(tagSelector).append('<div class="tps-workshop-tab-controls"></div>');

            $.each(days, function (key, val) {
                var currentDateLabel = $.format.date(new Date(val.date), TPS.WorkshopCalendar.dateFormat);

                // Build HTML containers
                $(tagSelector).append($('<div/>').attr('id', 'tps-workshop-calendar-day-' + key).addClass('tps-workshop-calendar-day').addClass('tps-workshop-calendar-day-' + key));

                // Tab title
                $(tagSelector).find('.tps-workshop-tab-controls').append('<a href="#tps-workshop-calendar-day-' + key + '">' + currentDateLabel + '</a>');

                // FullCalendar container
                $('.tps-workshop-calendar-day-' + key).append($('<div/>').addClass('tps-workshop-calendar-day-container'));

                // FullCalendar
                var viewTreashold = 768;
                var calendarView = TPS.WorkshopCalendar.window.width() >= viewTreashold ? 'agendaDay' : 'basicDay';

                $('.tps-workshop-calendar-day-' + key + ' .tps-workshop-calendar-day-container').fullCalendar({
                    defaultView: calendarView,
                    locale: 'en-ca',
                    header: false,
                    footer: false,
                    navLinks: false,
                    columnHeader: false,
                    allDaySlot: false,
                    slotEventOverlap: false,
                    height: 'auto',
                    timeFormat: 'h:mma',
                    minTime: '08:00:00',
                    maxTime: '19:00:00',
                    defaultDate: days[key].date,
                    events: days[key].sessions,

                    // Event/slot render
                    eventRender: function (event, element) {
                        // @TODO - Each text has a different colour, so this is not working on front-end
                        // if ( event.txtColor ) {
                        //     element.css( 'color', event.txtColor );
                        // }

                        if (event.bgColor) {
                            element.css('background-color', event.bgColor);
                        }

                        if (event.borderColor) {
                            element.css('border-color', event.borderColor);
                        }

                        element.find('.fc-time').insertAfter(element.find('.fc-title'));

                        if (event.description) {
                            element.find('.fc-title').after(
                                $('<div/>').addClass('fc-description').text(event.description)
                            );
                        }

                        if (event.summary) {
                            element.find('.fc-time').after(
                                $('<div/>').addClass('fc-detail').hide().text(event.summary)
                            );

                            element.click(function () {
                                $('html').addClass('tps-workshop-modal-open');
                                $('.tps-workshop-schedule').append('<div class="tps-workshop-calendar-modal-overlay"></div>');
                                $('.tps-workshop-schedule').append('<div class="tps-workshop-calendar-modal"></div>');

                                var currentDateLabel = $.format.date(new Date(event.start._i), TPS.WorkshopCalendar.dateFormat);
                                var currentTimeLabel = $.format.date(new Date(event.start._i), TPS.WorkshopCalendar.startTimeFormat) + '-' + $.format.date(new Date(event.end._i), TPS.WorkshopCalendar.endTimeFormat);

                                var detailHtml = '<h2>' + event.title + '</h2>';
                                detailHtml += '<div class="tps-workshop-calendar-modal-datetime"><time class="tps-workshop-calendar-modal-date">' + currentDateLabel + '</time><time class="tps-workshop-calendar-modal-time">' + currentTimeLabel + '</time></div>';
                                if (event.presenter) {
                                    detailHtml += '<div class="tps-workshop-calendar-modal-sub-title"><strong>Presenter:</strong> ' + event.presenter + '</div>';
                                }
                                detailHtml += '<p>' + event.summary + '</p>';

                                $('.tps-workshop-schedule').find('.tps-workshop-calendar-modal').append(
                                    $('<div class="tps-workshop-calendar-modal-container"><button type="button" class="tps-workshop-calendar-modal-close">×</button><div class="tps-workshop-calendar-modal-detail">' + detailHtml + '</div></div>')
                                );

                                // Destroy Detail

                                $('.tps-workshop-schedule').find('.tps-workshop-calendar-modal .tps-workshop-calendar-modal-close').click(function () {
                                    $(this).closest('.tps-workshop-calendar-modal').siblings('.tps-workshop-calendar-modal-overlay').remove();
                                    $(this).closest('.tps-workshop-calendar-modal').remove();
                                    $('html').removeClass('tps-workshop-modal-open');
                                });

                                $('.tps-workshop-schedule').find('.tps-workshop-calendar-modal-overlay').click(function () {
                                    $(this).siblings('.tps-workshop-calendar-modal').remove();
                                    $(this).remove();
                                    $('html').removeClass('tps-workshop-modal-open');
                                });
                            });
                        }
                    },

                    // On window resize
                    windowResize: function (view) {
                        var calendarView = TPS.WorkshopCalendar.window.width() >= viewTreashold ? 'agendaDay' : 'basicDay';

                        $('.tps-workshop-calendar-day-container').fullCalendar('changeView', calendarView);
                        $(view.el).closest('.tps-workshop-calendar-day-container').fullCalendar('rerenderEvents');
                    },
                });
            });

            // Tabs
            $(tagSelector).find('.tps-workshop-calendar-day').hide();

            if (0 == location.hash.indexOf('#tps-workshop-calendar-day-') && $(tagSelector).find(location.hash).size()) {
                $(tagSelector).find(location.hash).show();
                $(tagSelector).find('.tps-workshop-calendar-day-0, .tps-workshop-tab-controls a[href="' + location.hash + '"]').addClass('active');
            } else {
                $(tagSelector).find('.tps-workshop-calendar-day-0').show();
                $(tagSelector).find('.tps-workshop-calendar-day-0, .tps-workshop-tab-controls a[href="#tps-workshop-calendar-day-0"]').addClass('active');
            }

            $(tagSelector).find('.tps-workshop-tab-controls a').click(function (e) {
                e.preventDefault();

                $(tagSelector).find('.tps-workshop-calendar-day-0, .tps-workshop-tab-controls a').removeClass('active');
                $(this).addClass('active');
                $(tagSelector).find('.tps-workshop-calendar-day').hide();
                $(tagSelector).find($(this).attr('href')).show();

                // ReRender calendars
                $('.tps-workshop-calendar-day-container').fullCalendar('rerenderEvents');
            });
        });
    }

    // Custom events order
    // Reference: line 4840 from fullcalendar.js changed
    sortEvent(seg1, seg2) {
        return seg1.footprint.eventDef.miscProps.order > seg2.footprint.eventDef.miscProps.order ? 1 : -1;
    }
}

TPS.WorkshopCalendar = new WorkshopCalendar();
