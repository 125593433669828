'use strict';

class AmazonSearch {

    // Constructor

    constructor() {

        this.window = $(window);
        this.document = $(document);
        this.fqParam = '';
        this.coursesSearched = false;
    }

    // Init before frameworks
    init() {
        
        let _this = this;
    
        _this.setUpClearSearch();

        if (jQuery('[data-aws-field="courseorgid"]').length > 0) {
            _this.filterByCourseId();
        }
        
        if (typeof window.TPSCloudSearchOptions !== 'undefined') {
            _this.fqParam = window.TPSCloudSearchOptions.searchParams.fq;
        }

        if (window.globalSuggesterOptions) {
            _this.setupGlobalSuggestions();
        }

        if (window.CourseIdSuggesterOptions) {
            _this.setupCourseIdSuggestions();   
        }
    }

    // Init after frameworks
    initAfterFrameworks() { }

    setupGlobalSuggestions() {

        let _this = this;

        $('.global-search-input').each(function () {

            var appendToId = '#' + $(this).parents('.searchBox').attr('id');
            $(this).autocomplete({
                appendTo: appendToId,
                classes: {
                    'ui-autocomplete': 'tps-autocomplete-list'
                },
                source: _this.getGlobalAutoCompleteSource,
                select: _this.onGlobalAutoCompleteSelect
            });

            $(this).data('ui-autocomplete')._renderMenu = function (ul, items) {
                var that = this;                
                $.each(items, function (index, item) {
                    that._renderItemData(ul, item);
                });
            };

        });

        _this.document.on('click', '.tps-suggester-search-link', function (e) {
            e.preventDefault();
            $(this).parents('.searchBox').find('[type="submit"]').trigger('click');
        });

    }

    // get autocomplete source from AWS (cloudsearch plugin)
    getGlobalAutoCompleteSource(inputText, setSource) {
        window.globalSuggesterOptions["searchParams"]["q"] = inputText.term.replace(/[']/gi, "\\'");
        window.globalSuggesterOptions["searchParams"]["suggester"] = 'rcmusic_suggester';
        window.globalSuggesterOptions["onLoad"] = function (a) {
            let suggArr = [];
            $.each(this.suggest.suggestions, function (k, v) {
                suggArr.push(v.suggestion);
            });
            setSource(suggArr);
        };        
        $('.global-search-input').cloudsearchSuggester(window.globalSuggesterOptions);
    }

    onGlobalAutoCompleteSelect(e, elem) {   
        e.preventDefault();
        $(e.target).parents('.searchBox').find('.global-search-input').val(elem.item.value);
        $(e.target).parents('.searchBox').find('[type="submit"]').trigger('click');
        
    }

    setupCourseIdSuggestions() {
        let _this = this;
       
        var appendToId = '#couseId-wrapper'
        $('.couseId-filter-input').autocomplete({
            appendTo: appendToId,
            classes: {
                'ui-autocomplete': 'tps-autocomplete-list courses'
            },
            source: _this.getCourseIdAutoCompleteSource,
            select: _this.onCourseIdAutoCompleteSelect
        });

        $('.couseId-filter-input').data('ui-autocomplete')._renderMenu = function (ul, items) {
            var that = this;
            $.each(items, function (index, item) {
                that._renderItemData(ul, item);
            });
        };
        
    }

    getCourseIdAutoCompleteSource(inputText, setSource) {
        window.CourseIdSuggesterOptions["searchParams"]["q"] = inputText.term.replace(/[']/gi, "\\'");
        window.CourseIdSuggesterOptions["searchParams"]["suggester"] = 'rcs_course_id';
        window.CourseIdSuggesterOptions["onLoad"] = function (a) {
            let suggArr = [];
            $.each(this.suggest.suggestions, function (k, v) {
                suggArr.push(v.suggestion);
            });
            setSource(suggArr);
        };                
        $('.couseId-filter-input').cloudsearchSuggester(window.CourseIdSuggesterOptions);
    }

    onCourseIdAutoCompleteSelect(e, elem) {
        e.preventDefault();
    }

    addViewAllLink(list) {
        let linkText = (window.globalSuggesterOptions.actionText != '' && window.globalSuggesterOptions.actionText) ? window.globalSuggesterOptions.actionText : "View All Results";

        let item = $('<li/>').addClass('tps-suggester-action ui-state-disabled');
        let itemLink = $('<a/>').addClass('tps-suggester-search-link').attr('href','#').text(linkText);

        $(list).append($(item).append(itemLink));
    }

    addFeaturedHeadline(list) {
        let headlineText = (window.globalSuggesterOptions.headlineText != '' && window.globalSuggesterOptions.headlineText) ? window.globalSuggesterOptions.headlineText : "Featured Results";
        let item = $('<li/>').addClass('tps-suggester-title ui-state-disabled').text(headlineText);
        $(list).prepend($(item));
    }
    
    setUpClearSearch() {
        var searchText = TPS.Utility.getURLParameter('searchtext');
        var searchBox = $('.tps-search-box.search-results-page');
        if (searchBox.length > 0 && searchText) {
            var searchInput = searchBox.find('.form-control[type="text"]');
            // set the value of the search input as the searchtext
            // kentico does not do this by default
            searchInput.val(decodeURIComponent(searchText.replace(/\+/g, ' ')));
            searchBox.find('.tps-search-box-clear').show().on('click', function (e) {
                e.preventDefault();
                var target_url = TPS.Utility.updateUrlParameter(document.location.href, 'searchtext', '');
                window.history.pushState({ 'url': target_url }, '', target_url);
                searchInput.val('');
                window.TPSCloudSearchOptions.searchParams.q = "matchall";
                $('#tps-cloudsearch-results').cloudsearch(window.TPSCloudSearchOptions);
            });
        } else {
            searchBox.find('.tps-search-box-clear').hide();
        }
    }
  
    handleTabFacet(e) {
        
        e = e || window.event;
        e.preventDefault();
        var targ = e.target || e.srcElement;
        if (targ.nodeType && targ.nodeType === 3) targ = targ.parentNode; // defeat Safari bug
        var elem = $(targ);


        if (elem.attr('aria-selected') !== 'true') {

            $(targ).parents('.tps-aws-facet-tabs').find('.tabs-title').removeClass('is-active').find('a').attr('aria-selected', 'false');
            $(targ).attr('aria-selected', 'true').parents('.tabs-title').addClass('is-active');
      
            var queryFilter = '';
            if ($(targ).data('cloudsearchFacetValue') != '' && $(targ).data('cloudsearchFacetValue') != '_showall_' && $(targ).data('cloudsearchFacetValue') && $(targ).data('cloudsearchFacetName') && $(targ).data('cloudsearchFacetName') != '') {
                queryFilter = $(targ).data('cloudsearchFacetName') + ':\'' + $(targ).data('cloudsearchFacetValue') + '\'';
            }
            var hideControls = $(targ).data('tpsHideControls');
            var showControls = $(targ).data('tpsShowControls');

            if (hideControls) {
                $(hideControls).hide(0).each(function (k, v) {
                    TPS.Utility.clearFormFields(v);
                });
            }

            if (showControls) {
                $(showControls).show(0);
            }

            var filterRegex = new RegExp($(targ).data('cloudsearchFacetName') + ":\\'[\\S\\s]*\\'");
            
            var fq_prim = window.TPSCloudSearchOptions.searchParams.fq;

            var urlBase = document.location.href;

            if(window.TPSCloudSearchOptions.searchParams.fq.search(filterRegex) !== -1) {
      
                fq_prim = window.TPSCloudSearchOptions.searchParams.fq.replace(filterRegex, queryFilter);  
    
                
                window.TPSCloudSearchOptions.searchParams.fq = fq_prim;

                // reset pagination to first page
                window.TPSCloudSearchOptions.searchParams.start = 0;      
                if (window.TPSCloudSearchOptions.results.pager.updateHistory) {
                    urlBase = TPS.Utility.updateUrlParameter(document.location.href, 'p', '1');
                }
        
            } else if(queryFilter != '') {
                fq_prim = '(and ' + window.TPSCloudSearchOptions.searchParams.fq + ' ' + queryFilter + ')';  
                window.TPSCloudSearchOptions.searchParams.fq = fq_prim;
            }


            var target_url = TPS.Utility.updateUrlParameter(urlBase, 'tps_activeFacetTab', $(targ).data('cloudsearchFacetValue'));
            window.history.pushState({ 'url': target_url }, '', target_url);

            // get just the current path, plus any query string:
            var target_path = target_url.replace((document.location.protocol + "//" + document.location.host), "");
            // update kentico form target, so that tab is preserved on search:
            $("#form").attr('action', target_path);
            
            // execute the search
            $('#tps-cloudsearch-results').cloudsearch(window.TPSCloudSearchOptions);

      
        }
    }

    updateSearchHistory() {
            
        var facetParam, urlInit, urlNew, facetString, updatedHistoryUrl;
        urlInit = window.location.pathname + window.location.search;
        facetParam = window.TPSCloudSearchOptions.urlParameters.facets ? window.TPSCloudSearchOptions.urlParameters.facets : 'fc';
        // check to see if facets are already in the
        // query string, and if so, remove them
        if( urlInit.indexOf(facetParam) > -1 ) {
            urlNew = TPS.Utility.updateUrlParameter(urlInit, facetParam);
        } else {
            urlNew = urlInit;
        }
        // check to see if facets have been 
        // applied to the search options
        // and if so, add them to the query string
        if(window.TPSCloudSearchOptions.facetsSelected.length > 0) {                
            // facetString = .toString();
            facetString = '';
            $.each(window.TPSCloudSearchOptions.facetsSelected, function(k, v) {
                if(k > 0) {
                    facetString += ';';
                }
                facetString += encodeURIComponent(v.toString());
            });                
            updatedHistoryUrl = urlNew;
            updatedHistoryUrl += (urlNew.indexOf('?') > -1) ? '&' : '?';
            updatedHistoryUrl += facetParam + '=' + facetString;
        } else {
            updatedHistoryUrl = urlNew;
        }  
        // push the new search query string to the browser history
        window.history.pushState( { 'url' : updatedHistoryUrl }, '', updatedHistoryUrl );     
    }

    filterByCourseId() {
        let _this = this;
        let pattern = /\(prefix field=courseorgid \'[a-zA-z0-9\.]{1,10}\'\)/;
      
        $('[data-aws-field="courseorgid"]').on('keydown', function (e) {

            var keycode = (e.keyCode ? e.keyCode : e.which);

            if (keycode === 13) {
                e.preventDefault();
                console.log((_this.coursesSearched));
                if (_this.coursesSearched) {
                    _this.coursesSearched = false;
                    $('#tps-cloudsearch-results').cloudsearch(window.TPSCloudSearchOptions);
                    $(this).prop('disabled', true);
                }
            } 

        }).on('keyup', function (e) {

            var curr_val = $(this).val();
            
            if (curr_val.length > 1) {

                if (TPSCloudSearchOptions.searchParams.fq.search(pattern) !== -1) {
                    TPSCloudSearchOptions.searchParams.fq = TPSCloudSearchOptions.searchParams.fq.replace(pattern, "(prefix field=courseorgid '" + curr_val + "')");
                } else {
                    TPSCloudSearchOptions.searchParams.fq = "(and " + TPSCloudSearchOptions.searchParams.fq + " (prefix field=courseorgid '" + curr_val + "') )";
                }

            } else {

                TPSCloudSearchOptions.searchParams.fq = TPSCloudSearchOptions.searchParams.fq.replace("(and (", "(").replace(") )", ")").replace(pattern, "");

            }                       

        });
    }

};

TPS.AmazonSearch = new AmazonSearch();

