'use strict';

class WizardQuestion {
    constructor(question) {
        this.id = question.id;
        this.template = question.template; // Checkboxes / Boxes
        this.startLevelText = question.startLevelText || '';
        this.pageTitle = question.pageTitle || 'RCM Digital Learning Guide';
        this.question = question.question;
        this.answers = [];
        this.parentQuestionId = question.parentQuestionId || null;
        this.parentAnswerId = question.parentAnswerId || null;
        this.levelIncrease = question.levelIncrease || false;
        this.noAnswerRedirect = question.noAnswerRedirect || null;
        this.selectedAnswer = null;
        this.introTexts = question.intro || [];
        this.circleText = question.circleText || '';
        this.sequence = question.sequence || '';
        this.maxLevel = question.maxLevel || null;
        this.correctAnswerId = question.correctAnswerId || null;
        this.audio = question.audio || null;
        this.recommendation = question.recommendation || { answerId: null, intro: '', content: '', apps: [] };

        for (var i = 0; i < question.answers.length; i++) {
            this.answers.push(new WizardAnswer(question.answers[i]));
        }
    }

    render(toBox, state) {
        $('#wiz-page-title').html(this.pageTitle);

        if (this.template === 'checkboxes') {
            this.renderAsCheckboxes(toBox, state);
        } else if (this.template === 'boxes') {
            this.renderAsBoxes(toBox);
        } else {
            this.renderAsAudio(toBox, state);
        }
    }

    renderAsBoxes(toBox) {
        var html = '<div class="wiz-box-question">';
        html += `<p>${this.question}</p>`;
        html += '<ul class="wiz-mobile-flex-column wiz-list-answers">';

        for (var i = 0; i < this.answers.length; i++) {
            html += `<li class="wiz-flex-1 wiz-list-answer" id="${this.answers[i].id}">`;
            html += this.answers[i].getAnswerHTML();
            html += '</li>';
        }

        html += '</ul>';
        html += '</div>';

        toBox.append(html);

        var self = this;

        $('.wiz-list-answer').click(function (e) {
            // e.preventDefault();

            if (this.id === self.selectedAnswer) {
                self.selectedAnswer = null;
                $(this).removeClass('wiz-selected');
                $(`#${this.id}-checkbox`).removeClass('wiz-answer-checkbox-selected');
            } else {
                if (self.selectedAnswer !== null) {
                    $(`#${self.selectedAnswer}`).removeClass('wiz-selected');
                    $(`#${self.selectedAnswer}-checkbox`).removeClass('wiz-answer-checkbox-selected');
                }

                self.selectedAnswer = this.id;
                $(this).addClass('wiz-selected');
                $(`#${this.id}-checkbox`).addClass('wiz-answer-checkbox-selected');
            }

            $(document).trigger('selectedAnswerChanged', [self.selectedAnswer]);
        });
    }

    renderAsCheckboxes(toBox, state) {
        var html = '';
        html += this.getIntroTextHtml(state);

        if (this.startLevelText) {
            html += this.getStartLevelTextHtml(state);
        }

        html += `<p>${this.question}</p>`;
        html += `<div class="wiz-flex wiz-flex-row wiz-checkboxes-content">`;
        html += `<div class="wiz-flex-1 wiz-checkboxes-circle-container">`;
        html += `<div class="wiz-checkboxes-circle-wrapper">`;
        html += `<div id="wiz-checkbox-progress" class="wiz-checkboxes-circle wiz-checkboxes-circle-0">`;
        html += `<span class="wiz-checkboxes-circle-text">`;
        html += `<h4 class="wiz-h4 wiz-circle-h4">${this.circleText}</h4>`;
        html += `</span>`;
        html += `</div>`;
        html += `<div class="wiz-circle-line wiz-circle-line-1"></div>`;
        html += `<div class="wiz-circle-line wiz-circle-line-2"></div>`;
        html += `<div class="wiz-circle-line wiz-circle-line-3"></div>`;
        html += `<div class="wiz-circle-line wiz-circle-line-4"></div>`;
        html += `</div></div>`;
        html += '<div id="prep-checkboxes" class="wiz-flex-1 wiz-checkboxes">';

        for (var i = 0; i < this.answers.length; i++) {
            var ans = this.answers[i];
            html += '<div class="wiz-checkboxes-checkbox">';
            html += `<input type="checkbox" id="${ans.id}" name="prep" value="${ans.id}">`;
            html += `<label for="${ans.id}">${ans.description}</label>`;
            html += '</div>';
        }

        html += "</div>";

        toBox.append(html);

        var self = this,
            $circle, currentProgressClass,
            count, totalCount,
            progress,
            progressClassBase = 'wiz-checkboxes-circle-';

        $('.wiz-checkboxes-checkbox').click(function (e) {
            // e.preventDefault();

            $circle = $('#wiz-checkbox-progress');
            currentProgressClass = $circle.attr('class').match(/wiz\-checkboxes\-circle\-[0-4]/g);

            if (currentProgressClass) {
                $circle.removeClass(currentProgressClass[0]);
            }

            totalCount = 0;
            count = 0;

            $('#prep-checkboxes div input[type=checkbox]').each(function () {
                totalCount = totalCount + 1;

                if ($(this).is(':checked')) {
                    count = count + 1;
                }
            });

            progress = count / totalCount;

            if (count === 0) {
                $circle.addClass(progressClassBase + 0);
            } else if (progress <= .25) {
                $circle.addClass(progressClassBase + 1);
            } else if (progress > .25 && progress <= .50) {
                $circle.addClass(progressClassBase + 2);
            } else if (progress > .50 && progress <= .75) {
                $circle.addClass(progressClassBase + 3);
            } else if (progress > .75) {
                if (count !== totalCount) {
                    $circle.addClass(progressClassBase + 3);
                } else {
                    $circle.addClass(progressClassBase + 4);
                }
            }
        });
    }

    renderAsAudio(toBox, state) {
        var html = '';
        html += this.getIntroTextHtml(state);

        if (this.startLevelText) {
            html += this.getStartLevelTextHtml(state);
        }

        html += `<p>${this.circleText} - Question ${this.sequence}</p>`;
        html += `<div class="wiz-flex wiz-flex-row wiz-mobile-flex-column wiz-checkboxes-content wiz-audio-wrapper">`;
        html += `<div class="wiz-flex-1">`;
        html += `<h3 class="wiz-h3-audio-question">${this.question}</h3>`;
        html += `<div id="wiz-checkbox-progress" class="wiz-checkboxes-circle wiz-checkboxes-circle-audio wiz-checkboxes-circle-0">`;
        html += `<span id="wiz-toggle-audio" class="wiz-checkboxes-circle-play"></span>`;
        html += `</div>`;
        html += `<span>Press play button to hear sound.</span>`;
        html += `</div>`;
        html += '<div id="prep-checkboxes" class="wiz-flex-1 wiz-checkboxes">';

        for (var i = 0; i < this.answers.length; i++) {
            var ans = this.answers[i];
            html += '<div class="wiz-checkboxes-checkbox">';
            html += `<input type="radio" id="${ans.id}" name="prep" value="${ans.id}">`;
            html += `<label for="${ans.id}">`;
            html += ans.image ? `<img src="${ans.image}" alt="Answer ${ans.id}" />` : ans.description;
            html += `</label>`;
            html += '</div>';
        }

        html += "</div>";
        html += `<audio controls id="wiz-audio-question" style="display: none;">`;
        html += `<source src="${WIZ_BASE_PATH}assets/${this.audio}" type="audio/mpeg">`;
        html += `Your browser does not support the audio element.`;
        html += `</audio controls>`;

        toBox.append(html);

        var self = this, audioQ = $('#wiz-audio-question');

        // Change button if audio ended
        audioQ.on('ended', function () {
            $('#wiz-toggle-audio').removeClass('wiz-checkboxes-circle-pause');
            $('#wiz-toggle-audio').addClass('wiz-checkboxes-circle-play');
        });

        $('#wiz-toggle-audio').click(function (e) {
            // e.preventDefault();

            if ($(this).hasClass('wiz-checkboxes-circle-play')) {
                // Audio is currently paused
                $(this).removeClass('wiz-checkboxes-circle-play');
                $(this).addClass('wiz-checkboxes-circle-pause');
                audioQ[0].play();
                audioQ[0].currentTime = 0;
            } else {
                // Audio is currently playing
                $(this).removeClass('wiz-checkboxes-circle-pause');
                $(this).addClass('wiz-checkboxes-circle-play');
                audioQ[0].pause();
                audioQ[0].currentTime = 0;
            }
        });

        $('#prep-checkboxes div input[type=radio]').click(function (e) {
            // e.preventDefault();

            if (this.id === self.selectedAnswer) {
                self.selectedAnswer = null;
                $(this).removeClass('wiz-selected');
            } else {
                if (self.selectedAnswer !== null) {
                    $(`#${self.selectedAnswer}`).removeClass('wiz-selected');
                }

                self.selectedAnswer = this.id;
                $(this).addClass('wiz-selected');
            }

            $(document).trigger('selectedAnswerChanged', [self.selectedAnswer]);
        });
    }

    getStartLevelTextHtml(state) {
        var i, j, html = '', self = this, found = false, introFound;

        for (i = 0; i < this.startLevelText.length && !found; i++) {
            introFound = false;

            for (j = 0; j < state.questions.length && !introFound; j++) {
                if (self.startLevelText[i].parentQuestionId === state.questions[j].questionId) {
                    if (self.startLevelText[i].parentAnswerId === state.questions[j].answer) {
                        introFound = true;
                        found = true;
                        html += `<div class="start-level-text">${self.startLevelText[i].text}</div>`;
                    }
                }
            }
        }

        return html;
    }

    getIntroTextHtml(state) {
        var i, j, html = '', self = this, found = false, introFound;

        for (i = 0; i < this.introTexts.length && !found; i++) {
            introFound = false;

            for (j = 0; j < state.questions.length && !introFound; j++) {
                if (self.introTexts[i].parentQuestionId === state.questions[j].questionId) {
                    if (self.introTexts[i].parentAnswerId === state.questions[j].answer) {
                        introFound = true;
                        found = true;
                        html += `<div class="intro-text"><p>${self.introTexts[i].text}</p></div>`;
                    }
                }
            }
        }

        return html;
    }
}
