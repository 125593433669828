'use strict';

class Video {
    // Constructor

    constructor() {
        this.window = $(window);
        this.document = $(document);
    }

    // Init before frameworks
    init() {
        // this.VideoModalCTA = $();
    }

    // Init after frameworks
    initAfterFrameworks() {
        this.document.on('click', '.tps-video-modal-link', function(e) {
            e.preventDefault();
            let videoUrl = $(this).data('videoUrl');
            let videoTitle = $(this).data('videoTitle');
            let videoSize = $(this).data('videoSize');
            let vimeoID = TPS.Video.getVimeoID(videoUrl);
            let youtubeID = TPS.Video.getYoutubeID(videoUrl);
            let livestreamID = TPS.Video.getLivestreamID(videoUrl);
            let videoID = vimeoID || youtubeID || livestreamID;
            let videoType = vimeoID ? 'v' : (youtubeID ? 'yt' : 'ls');

            TPS.Video.openVideoModal(videoID, videoTitle, videoType, videoSize);
        });
    }

    getYoutubeID(url) {
        var regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        var match = url.match(regExp);
        return match ? RegExp.$1 : false;
    }

    getVimeoID(url) {
        var regExp = /https?:\/\/(?:www\.)?(?:player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
        var match = url.match(regExp);
        return match ? RegExp.$3 : false;
    }

    // Livestream ID will be the entire URL - ready to be displayed - since the video ID is not available on the shared URL
    getLivestreamID(url) {
        var regExp = /(https?:\/\/(?:www\.)?livestream.com\/accounts\/[0-9]+\/events\/[0-9]+\/player(?:$|\/.*|\?.*))/;
        var match = url.match(regExp);
        return match ? RegExp.$1 : false;
    }

    openVideoModal(videoID, videoTitle, videoType, videoSize) {
        var options, elem, modal, videoIDFormatted;

        videoIDFormatted = videoID.replace(/[^0-9a-zA-Z]/gi, '');

        options = {
            resetOnClose: true,
            additionalOverlayClasses: 'tps-video-modal-overlay',
            appendTo: '#form',
            // deepLink: true,
            // updateHistory: true
        }

        elem = $('#video-modal-' + videoIDFormatted);

        if (elem.length == 0) {
            elem = $('<div>').attr('id', 'video-modal-' + videoIDFormatted).addClass('reveal video-modal ' + videoSize);
            elem.append(this.createVideoModalContent(videoID, videoTitle, videoType));
            modal = new Foundation.Reveal(elem, options);
        }

        elem.foundation('open');
    }

    createVideoModalContent(videoID, videoTitle, videoType) {
        // videoSrc from Livestream is empty because the videoID is the entire URL - ready to be displayed
        let videoSrc = videoType == 'v' ? 'https://player.vimeo.com/video/' : (videoType == 'yt' ? 'https://www.youtube.com/embed/' : '');

        let modalContent = ' <button class="close-button" data-close type="button"><span class="sr-only">Close video modal</span>&times;</button>';
            modalContent += '<div class="tps-video-frame-wrapper"><iframe frameborder="0" name="' + videoTitle + '" allowfullscreen="" src="'+ videoSrc + videoID + '" class="tps-video-frame">' + videoTitle + '</iframe></div>';

        return modalContent;
    }
}

TPS.Video = new Video();
