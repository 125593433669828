'use strict';

class FindTeacherLandingPage {
    // Constructor
    constructor() {
        this.window = $(window);
        this.document = $(document);
    }

    // Init before frameworks
    init() {
        this.form = $('#findateacher-fatlp-form');

        if (this.form.length > 0) {
            this.enableAlgoliaForm();
        }
    }

    // Init after frameworks
    initAfterFrameworks() {
        
    }

    // Enable the Algolia plugin
    enableAlgoliaForm() {
        this.form.algolia({
            formValidation: this.algoliaFormValidation,
            onSubmit: this.algoliaFormOnSubmit
        });

        this.onAutocomplete();
        this.onFormSubmit();
    }

    // Validate the form
    algoliaFormValidation(form) {
        var isFormValid = true,
            $postalCode = form.find('#postal-code'),
            $distanceSelect = form.find('#distance'),
            $location = form.find('#location'),
            $place = form.find('#place'),
            // $online = form.find('#teachingLocation'),
            $disciplineSelect = form.find('#discipline');

        if ($location.length > 0 && $place.length > 0) {
            $location.siblings('.fieldset-error').remove();

            if ($.trim($location.val()).length === 0 || $.trim($place.val()).length === 0) {
                $location.addClass('highlight');
                $location.after('<span class="fieldset-error">' + TPS.FindTeacherLandingPage.getErrorFieldText() + '</span>');
                isFormValid = false;
            } else {
                $location.removeClass('highlight');
            }
        } else {
            $postalCode.siblings('.fieldset-error').remove();

            if ($.trim($postalCode.val()).length === 0) {
                $postalCode.addClass('highlight');
                $postalCode.after('<span class="fieldset-error">' + TPS.FindTeacherLandingPage.getErrorFieldText() + '</span>');
                isFormValid = false;
            } else {
                $postalCode.removeClass('highlight');
            }

            $distanceSelect.siblings('.fieldset-error').remove();

            if ($.trim($distanceSelect.val()).length === 0) {
                $distanceSelect.addClass('highlight');
                $distanceSelect.after('<span class="fieldset-error">' + TPS.FindTeacherLandingPage.getErrorFieldText() + '</span>');
                isFormValid = false;
            } else {
                $distanceSelect.removeClass('highlight');
            }
        }
        
        return isFormValid;
    }

    // Process the search results
    algoliaFormOnSubmit() {
        var $postalCode = TPS.FindTeacherLandingPage.form.find('#postal-code'),
            $distanceSelect = TPS.FindTeacherLandingPage.form.find('#distance'),
            $location = TPS.FindTeacherLandingPage.form.find('#location'),
            $place = TPS.FindTeacherLandingPage.form.find('#place'),
            $teachingLocation = TPS.FindTeacherLandingPage.form.find('#teachingLocation'),
            $disciplineSelect = TPS.FindTeacherLandingPage.form.find('#discipline'),
            $fullName = TPS.FindTeacherLandingPage.form.find('#fullName');

        if ($location.length > 0 && $place.length > 0) {
            document.location.href = TPS.RcmFindTeacherLinks.tool + '?place=' + encodeURIComponent($.trim($place.val())) + '&disciplines=' + encodeURIComponent($.trim($disciplineSelect.val())) + '&fullName=' + encodeURIComponent($.trim($fullName.val())) + '&teachingLocation=' + encodeURIComponent($.trim($teachingLocation.val()));
        } else {
            document.location.href = '/find-a-teacher?postal_code=' + $.trim($postalCode.val()) + '&distance=' + $.trim($distanceSelect.val()) + '&discipline=' + $.trim($disciplineSelect.val()) + '&fullName=' + encodeURIComponent($.trim($fullName.val()));
        }
    }

    // On autocomplete
    onAutocomplete() {
        var $location = TPS.FindTeacherLandingPage.form.find('#location');
        var $place = TPS.FindTeacherLandingPage.form.find('#place');

        if ($location.length > 0 && $place.length > 0) {
            var options = { componentRestrictions: { country: ['ca', 'us'] } };
            var autocomplete = new google.maps.places.Autocomplete($location[0], options);

            // Google listener
            autocomplete.addListener('place_changed', function () {
                var place = autocomplete.getPlace();

                if (!place || !place.geometry || !place.address_components) {
                    // $location.data('geoCountry', null);
                    // $location.data('geoLat', null);
                    // $location.data('geoLng', null);
                    $place.val('');
                } else {
                    // $(place.address_components).each(function (index, value) {
                    //     if (value.types.indexOf('country') != -1) {
                    //         $location.data('geoCountry', value.short_name);
                    //     }
                    // });

                    // $location.data('geoLat', place.geometry.location.lat());
                    // $location.data('geoLng', place.geometry.location.lng());
                    $place.val(place.place_id);
                }
            });

            $location[0].addEventListener('keydown', (e) => {
                if (e.which === 13) {
                    e.preventDefault();
                }
            });
        }
    }

    // On form submit (enter key)
    onFormSubmit() {
        var $fields = TPS.FindTeacherLandingPage.form.find('#postal-code, #distance, #location, #discipline');

        $fields.on('keypress', function (e) {
            if (e.which == 13) {
                e.preventDefault();
                TPS.FindTeacherLandingPage.form.find('input[type="submit"]').trigger('click');
                return false;
            }
        });
    }

    // Get error field text translated
    getErrorFieldText() {
        return (document.referrer.indexOf('chinese') > -1 || document.location.href.indexOf('chinese') > -1) ? '這是必填欄' : 'This field is required';
    }
};

TPS.FindTeacherLandingPage = new FindTeacherLandingPage();
