'use strict';

class WizardCore {
    constructor(appBox, basePath) {
        window.WIZ_BASE_PATH = basePath;
        
        this.$appBox = $(appBox);
        this.$content = null;
        this.currentLocation = 'landing';
        this.questionProgress = 1;
        this.currentQuestionId = 1;
        this.currentAnswerId = null;
        this.currentLevel = 0;
        this.maxLevel = 1;
        this.audioMoveToNextThreshold = 3;
        this.audioMoveDownThreshold = 1;

        this.userInputState = {
            questions: []
        };

        if (this.$appBox.length > 0) {
            this.initRCMWizard();
        }
    }

    initRCMWizard() {
        var self = this;

        this._loadQuestions(function () {
            self.loadLanding();
        });
    }

    loadLanding() {
        var self = this;

        this.$appBox.load(`${WIZ_BASE_PATH}wizard.html`, function () {
            $('#startFlow').click(function (e) {
                e.preventDefault();

                self.$appBox.load(`${WIZ_BASE_PATH}question.tpl.html`, function () {
                    self.$content = $('#content');
                    self.currentLocation = 'flow';
                    self.renderNextQuestion();
                });
            });
        });
    }

    _findPreviousAudioQuestion(question) {
        const self = this;
        var found = false, correctAnswers = (question.correctAnswerId === self.currentAnswerId) ? 1 : 0, prevQId = question.parentQuestionId, questionsGoneThrough = 0;

        // Checking previous answers to get number of correct answers
        while (!found && prevQId) {
            const prevQ = self._findQuestionById(prevQId);

            if (prevQ.levelIncrease) {
                found = true;
            } else {
                questionsGoneThrough = questionsGoneThrough + 1;
                prevQId = prevQ.parentQuestionId;
                const userAnswer = self.userInputState.questions.find(s => s.questionId === prevQ.id);

                if (userAnswer) {
                    if (prevQ.correctAnswerId === userAnswer.answer) {
                        correctAnswers = correctAnswers + 1;
                    }
                }
            }
        }

        return { questionsGoneThrough, correctAnswers, prevQId };
    }

    _findNextLevelAudioQuestion(question) {
        const self = this;
        var found = false, nextQ, prevQId = question.id;

        while (!found) {
            nextQ = self._findQuestionChild(prevQId);

            if (nextQ.levelIncrease) {
                found = true;
            } else {
                prevQId = nextQ.id;
            }
        }

        return nextQ;
    }

    renderNextQuestion() {
        const self = this;
        const nextQ = this._findQuestionById(this.currentQuestionId);
        this.selectedAnswer = null;
        this.$content.html(''); // Reset box

        nextQ.render(this.$content, this.userInputState);

        if (nextQ.template === 'boxes') {
            this.$content.append(`<a class="wiz-btn wiz-question-continue" id="nextQuestion" disabled>Continue</a>`);
        } else {
            var continueBtn = `<div class="wiz-flex wiz-flex-row wiz-buttons">`;
            //continueBtn += `<div class="wiz-flex-1 wiz-hide-sm"></div>`;
            continueBtn += `<div class="wiz-flex-1 wiz-align-center">`;
            continueBtn += `<a class="wiz-btn wiz-question-continue" id="nextQuestion" ${(nextQ.template === 'audio') ? 'disabled' : ''}>Continue</a>`;
            continueBtn += `</div>`;
            continueBtn += `</div>`;

            this.$content.append(continueBtn);
        }

        // Logic
        var $nextBtn = $('#nextQuestion'), selectedAnswer;

        // Box specific logic
        if (nextQ.template === 'boxes' || nextQ.template === 'audio') {
            $(document).on('selectedAnswerChanged', function (event, answerId) {
                // $nextBtn.prop('disabled', !answerId);
                if (answerId) {
                    $nextBtn.removeAttr('disabled');
                } else {
                    $nextBtn.attr('disabled', true);
                }
                self.currentAnswerId = parseInt(answerId);
            });
        }

        $nextBtn.click(function (e) {
            e.preventDefault();

            if ($(this).attr('disabled')) {
                return false;
            }

            self._saveState();

            if (nextQ.maxLevel) {
                self.maxLevel = nextQ.maxLevel;
            }

            // Checkbox specific logic
            if (nextQ.template === 'checkboxes') {
                var checkedBoxes = [];

                $('#prep-checkboxes div input[type=checkbox]').each(function () {
                    if ($(this).is(':checked')) {
                        checkedBoxes.push($(this).attr('value'));
                    }
                });

                // Save users answers
                self.userInputState.questions.push({
                    questionId: nextQ.id,
                    circleText: nextQ.circleText,
                    answer: checkedBoxes.length,
                    actualAnswers: checkedBoxes
                });

                if (self.currentLevel === self._getMaxLevel()) {
                    self.renderRecommendation();
                    return;
                }

                // User should not be going to next level
                if (checkedBoxes.length < nextQ.answers.length) {
                    // If user haven't selected a single answer
                    if ((nextQ.answers.length <= 2 && checkedBoxes.length === 0)
                        || (nextQ.answers.length > 2 && checkedBoxes.length <= 1)) {
                        // If user is on higher level than one, move them back to previous level else show recommendation
                        if (self.currentLevel > 1) {
                            // If user has answered previous, show recommendation
                            const prevAnswer = self._getQuestionAnswerFromState(nextQ.parentQuestionId);
                            const prevQ = self._findQuestionById(nextQ.parentQuestionId);

                            if (prevAnswer) {
                                if (prevAnswer === prevQ.answers.length) {
                                    self.renderRecommendation();
                                }
                            } else {
                                // Get previous question
                                self.currentLevel = prevQ.levelIncrease ? self.currentLevel - 1 : self.currentLevel;
                                self.currentQuestionId = nextQ.parentQuestionId;
                                self.renderNextQuestion();
                            }
                        } else {
                            if (nextQ.noAnswerRedirect) {
                                window.location.href = nextQ.noAnswerRedirect;
                            } else {
                                self.renderRecommendation();
                            }
                        }
                    } else {
                        // Show recommendation because user have not selected all answers
                        self.renderRecommendation();
                    }
                } else {
                    // User should go to next level

                    // If User is on max level, show recommendation
                    if (self.currentLevel === self._getMaxLevel()) {
                        self.renderRecommendation();
                    } else {
                        const prevAnswer = self._getQuestionAnswerFromState(self.currentQuestionId);
                        self.currentLevel = nextQ.levelIncrease ? self.currentLevel + 1 : self.currentLevel;

                        if (prevAnswer && prevAnswer.answer === 0) {
                            self.renderRecommendation();
                        } else {
                            self.currentQuestionId = self._findQuestionChild(self.currentQuestionId).id;
                            self.renderNextQuestion();
                        }
                    }
                }
            } else if (nextQ.template === 'boxes') {
                self.userInputState.questions.push({
                    questionId: nextQ.id,
                    circleText: nextQ.circleText,
                    answer: self.currentAnswerId
                });

                self.questionProgress = self.questionProgress + 1;

                const answer = self._findAnswerById(nextQ.answers, self.currentAnswerId);

                if (answer && answer.redirectToQuestion) {
                    self.currentQuestionId = answer.redirectToQuestion;
                    const redirQ = self._findQuestionById(answer.redirectToQuestion);
                    self.currentLevel = self._setCurrentLevelByRedirect(redirQ.parentQuestionId, 0);
                } else if (answer.redirectExternal) {
                    window.location.href = answer.redirectExternal;
                    return;
                } else {
                    self.currentQuestionId = self._findQuestionChild(self.currentQuestionId, self.currentAnswerId).id;
                }

                self.currentAnswerId = null;
                self.renderNextQuestion();
            } else if (nextQ.template === 'audio') {
                self.userInputState.questions.push({
                    questionId: nextQ.id,
                    circleText: nextQ.circleText,
                    answer: self.currentAnswerId
                });

                // move to next question if we aren't on max level even if wrong answer
                // if level increase, check so all previous is correct
                // logic only applies to level increase levels
                if (nextQ.levelIncrease) {
                    var { questionsGoneThrough, correctAnswers, prevQId } = self._findPreviousAudioQuestion(nextQ);
                    const singleQuestion = (questionsGoneThrough === 0 && correctAnswers === 1)

                    // Threshold passed, moving to next question
                    if (correctAnswers > self.audioMoveToNextThreshold || singleQuestion) {
                        if (self.currentLevel === self._getMaxLevel()) {
                            // If we are on max level, show recommendation
                            self.renderRecommendation();
                            return;
                        } else {
                            // Moving to next question
                            self.currentLevel = self.currentLevel + 1;
                            const childQ = self._findQuestionChild(self.currentQuestionId);
                            const prevAnswer = self._getQuestionAnswerFromState(childQ.id);

                            if (prevAnswer) {
                                // Go to recommendation, because user have already answered next question
                                self.currentQuestionId = self._findNextLevelAudioQuestion(nextQ).id;
                                self.renderRecommendation();
                                return;
                            } else {
                                // Go to next quesiton
                                self.currentQuestionId = childQ.id;
                                self.renderNextQuestion();
                                return;
                            }
                        }
                    }

                    // Threshold not passed, moving down
                    if (correctAnswers <= self.audioMoveDownThreshold) {
                        // If we are on the first level
                        if (self.currentLevel === 0) {
                            // If current question have a custom template for no answers
                            if (nextQ.noAnswerRedirect) {
                                self.renderTemplate(nextQ.noAnswerRedirect);
                                return;
                            } else {
                                self.renderRecommendation();
                                return;
                            }
                        } else {
                            // Check if we've previously answered previous question
                            const prevAnswer = self._getQuestionAnswerFromState(prevQId);
                            const prevQ = self._findQuestionById(prevQId);
                            var { prevQId } = self._findPreviousAudioQuestion(prevQ);

                            if (prevAnswer) {
                                // We have already answered previous question, going to recommendation
                                self.renderRecommendation();
                                return;
                            } else {
                                self.currentLevel = self.currentLevel - 1;
                                self.currentQuestionId = self._findQuestionChild(prevQId, null).id;

                                if (self.currentLevel === 0) {
                                    // If current question have a custom template for no answers
                                    if (nextQ.noAnswerRedirect) {
                                        self.renderTemplate(nextQ.noAnswerRedirect);
                                        return;
                                    } else {
                                        self.currentQuestionId = prevQ.id;
                                        self.renderRecommendation();
                                        return;
                                    }
                                } else {
                                    self.renderNextQuestion();
                                    return;
                                }
                            }
                        }
                    }

                    // Current level is our actual level, show recommendation
                    if (correctAnswers > self.audioMoveDownThreshold && correctAnswers <= self.audioMoveToNextThreshold) {
                        self.renderRecommendation();
                        return;
                    }
                } else {
                    const childQ = self._findQuestionChild(self.currentQuestionId);
                    self.currentQuestionId = childQ.id;
                    self.renderNextQuestion();
                    return;
                }
            }
        });
    }

    renderTemplate(tpl) {
        this.$appBox.load(`${WIZ_BASE_PATH}${tpl}.html`);
    }

    renderRecommendation() {
        const self = this;

        this.$appBox.load(`${WIZ_BASE_PATH}recommendation.html`, function () {
            var currentQ = self._findQuestionById(self.currentQuestionId);
            var answer = self.userInputState.questions.find(s => s.questionId === currentQ.id);
            var recommendation = null;

            // Last level + All answers checked (checkboxes)
            if (self.currentLevel === self._getMaxLevel() && currentQ.template != 'audio' && currentQ.answers.length == answer.actualAnswers.length) {
                recommendation = currentQ.recommendation.find(s => s.finalRecommendation == true);
            // Last level + All answers checked (audios)
            } else if (self.currentLevel === self._getMaxLevel() && currentQ.template == 'audio' && self._getCorrectAnswersCounterByQuestionId(self.currentQuestionId) == 4) {
                recommendation = currentQ.recommendation.find(s => s.finalRecommendation == true);
            // More than one recommendation record
            } else if (currentQ.recommendation instanceof Array) {
                // const answer = self.userInputState.questions.find(s => s.questionId === currentQ.id);

                // No answer from the user, so pick the first one
                if (typeof answer.actualAnswers == 'undefined' || answer.actualAnswers.length === 0) {
                    recommendation = currentQ.recommendation[0];
                // If the user has answered more than one but less than total, so pick the first one
                } else if (answer.actualAnswers.length > 1) {
                    recommendation = currentQ.recommendation[0];
                // Find the recommendation based on the user answer
                } else {
                    recommendation = currentQ.recommendation.find(s => s.answerId == answer.actualAnswers[0]);
                }
            // Simple recommendation
            } else {
                recommendation = currentQ.recommendation;
            }

            // Music level
            var html = '', i, mobileMin, mobileMax, showMobile, mobileCount = 0;
            html += `<div class="wiz-level-container">`;
            html += `<div class="wiz-level-progress">`;

            mobileMax = self.currentLevel === self.maxLevel ? self.currentLevel :
                (self.currentLevel === 0 ? self.currentLevel + 2 : self.currentLevel + 1);
            mobileMin = self.currentLevel === 0 ? self.currentLevel :
                (self.currentLevel === self.maxLevel ? self.currentLevel - 2 : self.currentLevel - 1);

            for (i = 0; i <= self.maxLevel; i++) {
                mobileCount = (i >= mobileMin && i <= mobileMax) ? mobileCount + 1 : mobileCount;
                showMobile = (i >= mobileMin && i <= mobileMax) ? ` wiz-level-mobile-${mobileCount}` : ' wiz-hide-xs  wiz-show-md';

                if (i === self.currentLevel) {
                    html += `<div class="wiz-level wiz-level-${i}${showMobile}">`;
                    html += `<div class="wiz-level-selected">`;
                    html += `<span class="wiz-level-selected-content">${i === 0 ? 'P' : (i === 11 ? 'A' : i)}</span>`;
                    html += `<span class="wiz-level-selected-desc">${i === 0 ? 'Prep' : (i === 11 ? 'ARCT' : 'Level ' + i)}</span>`;
                    html += `</div>`;
                    html += `</div>`;
                } else {
                    html += `<div class="wiz-level wiz-level-${i}${showMobile}">${(i === 0) ? 'Prep' : (i === 11 ? 'ARCT' : i)}</div>`;
                }
            }

            html += `</div>`;
            html += `</div>`;
            $('#musicLevel').html(html);

            // Recommendation intro
            $('#recommendation-intro-text').html(recommendation.intro);

            // Recommendation content
            $('#recommendation-text-content').html(recommendation.content);

            // Recommended extra apps
            if (recommendation.apps.length > 0) {
                html = '';

                for (i = 0; i < recommendation.apps.length; i++) {
                    var currentApp = recommendation.apps[i];
                    html += `<div class="wiz-recommendation-app">`;
                    html += '<div class="wiz-flex wiz-flex-row wiz-flex-align-center wiz-flex-justify-content wiz-recommendation-also-apps">';

                    if (currentApp.img) {
                        html += '<div class="wiz-image-wrapper">';
                        html += `<img src="${currentApp.img}" alt="${currentApp.name}'s app-image." />`;
                        html += '</div>';
                    }

                    html += '<div>';
                    html += '<h3 class="wiz-h3">';

                    if (currentApp.appLink) {
                        html += `<a href="${currentApp.appLink}" target="_blank">${currentApp.name}</a>`;
                    } else {
                        html += currentApp.name;
                    }

                    html += '</h3>';
                    html += `<p>${currentApp.description}</p>`;
                    html += '</div>';
                    html += '</div>';
                    html += '</div>';
                }

                $('#recommended-extra-apps-title').show();
                $('#recommended-extra-apps').html(html).show();
            } else {
                $('#recommended-extra-apps-title').hide();
                $('#recommended-extra-apps').hide();
            }

            // Product level
            $('#recommended-product-level').html(recommendation.productTitle);

            if (recommendation.image) {
                $('#recommended-product-image').attr('src', recommendation.image).show();
            } else {
                $('#recommended-product-image').hide();
            }

            if ((new RegExp("itunes.apple.com", "ig")).test(recommendation.appLink)) {
                $('#recommended-product-app-link-v1').attr('href', recommendation.appLink).show();
                $('#recommended-product-app-link-v2').attr('href', '#').hide();
            } else {
                $('#recommended-product-app-link-v2').attr('href', recommendation.appLink).show();
                $('#recommended-product-app-link-v1').attr('href', '#').hide();
            }

            $('#wiz-print').click(function (e) {
                e.preventDefault();
                window.print();
            });

            $('#wiz-restart').click(function (e) {
                e.preventDefault();
                window.location.reload();
            });
        });
    }

    // Probably not needed?
    _saveState() {
        // @TODO
        // console.log('Lets save the state');
    }

    _loadQuestions(callback) {
        var self = this;

        $.get(`${WIZ_BASE_PATH}questions.txt`, function (data) {
            self.questions = [];
            data = JSON.parse(data);

            for (var i = 0; i < data.length; i++) {
                self.questions.push(new WizardQuestion(data[i]));
            }

            $.get(`${WIZ_BASE_PATH}theory-questions.txt`, function (theory) {
                theory = JSON.parse(theory);
                self.questions = [...self.questions, ...theory.map(s => new WizardQuestion(s))];

                $.get(`${WIZ_BASE_PATH}fourstar-questions.txt`, function (fourstar) {
                    fourstar = JSON.parse(fourstar);
                    self.questions = [...self.questions, ...fourstar.map(s => new WizardQuestion(s))];

                    callback();
                });
            });
        });
    }

    _findQuestionById(questionId) {
        var found = false, i, question;

        for (i = 0; i < this.questions.length && !found; i++) {
            if (this.questions[i].id === questionId) {
                question = this.questions[i];
                found = true;
            }
        }

        return question;
    }

    _findQuestionChild(questionId, answerId) {
        if (questionId === 1 && !answerId) {
            return this._findQuestionById(questionId);
        }

        var found = false, question = null, i;

        for (i = 0; i < this.questions.length && !found; i++) {
            if (this.questions[i].parentQuestionId === questionId) {
                if (answerId && this.questions[i].parentAnswerId) {
                    if (answerId === this.questions[i].parentAnswerId) {
                        question = this.questions[i];
                        found = true;
                    }
                } else {
                    question = this.questions[i];
                    found = true;
                }
            }
        }

        return question;
    }

    _findAnswerById(answers, answerId) {
        var i, answer = null;

        for (i = 0; i < answers.length && !answer; i++) {
            if (answers[i].id === answerId) {
                answer = answers[i];
            }
        }

        return answer;
    }

    _getCorrectAnswersCounterByQuestionId(questionId) {
        var i, self = this, q, counter, currentQ, questions;

        counter = 0;
        currentQ = self._findQuestionById(questionId);
        questions = self.userInputState.questions.filter(s => s.circleText === currentQ.circleText);

        for (i = 0; i < questions.length; i++) {
            q = self._findQuestionById(questions[i].questionId);

            if (q.template === 'audio') {
                if (q.correctAnswerId === questions[i].answer) {
                    counter++;
                }
            }
        }

        return counter;
    }

    _getMaxLevel() {
        return this.maxLevel;

        // var max = 0, i;

        // for (i = 0; i < this.questions.length; i++) {
        //     if (this.questions[i].levelIncrease) {
        //         max = max + 1;
        //     }
        // }

        // return max;
    }

    _getQuestionAnswerFromState(questionId) {
        var i, answer = null, c;

        for (i = 0; i < this.userInputState.questions.length && !answer; i++) {
            c = this.userInputState.questions[i];
            if (c.questionId === questionId) {
                answer = c.answer;
            }
        }

        return answer;
    }

    _setCurrentLevelByRedirect(questionId, level) {
        const question = this._findQuestionById(questionId);

        level = question.levelIncrease ? level + 1 : level;

        if (question.parentQuestionId) {
            return this._setCurrentLevelByRedirect(question.parentQuestionId, level);
        }

        return level;
    }
}

