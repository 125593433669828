'use strict';

class WizardAnswer {
    constructor(answer) {
        this.id = answer.id;
        this.title = answer.title;
        this.description = answer.description;
        this.image = answer.image || null; // Can be null
        this.redirectTemplate = answer.redirectTemplate || null; // Can be null, used if answer should redirect user elsewhere
        this.redirectExternal = answer.redirectExternal || null;
        this.redirectToQuestion = answer.redirectToQuestion || null; // Can be null, used if answer should redirect to other question
    }

    getAnswerHTML() {
        var html = '<div class="wiz-answer wiz-mobile-flex-row wiz-flex-align-center">';
        html += this.image ? `<img class="wiz-answer-image" src="${this.image}" alt="${this.title ? this.title : this.description} image"/>` : '';
        html += `<div id="${this.id}-checkbox" class="wiz-answer-checkbox"></div>`;
        html += `<div class="wiz-answer-text">`;
        html += this.title ? `<p class="h4 separator-left">${this.title}</p>` : '';
        html += `<p>`;
        html += this.description ? `${this.description}` : '';
        html += '</p>';
        html += '</div>';
        html += '</div>';

        return html;
    }
}
