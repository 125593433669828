'use strict';

class Wizard {
    // Constructor
    constructor() {
        this.window = $(window);
        this.document = $(document);
    }

    // Init before frameworks
    init() {}

    // Init after frameworks
    initAfterFrameworks() {
        this.app = new WizardCore(
            '#rcm-wizard',
            '/RCM/assets/digital-learning-wizard/'
        );
    }
}

TPS.Wizard = new Wizard();
