'use strict';

class Modal {
    // Constructor

    constructor() {
        this.window = $(window);
        this.document = $(document);
    }

    // Init before frameworks
    init() {

    }

    // Init after frameworks
    initAfterFrameworks() {

        this.document.on('click', '.tps-modal-link', function(e) {
            e.preventDefault();
            let videoUrl = $(this).data('videoUrl');
            let videoTitle = $(this).data('videoTitle');
            let vimeoID = TPS.Video.getVimeoID(videoUrl);
            let youtubeID = TPS.Video.getYoutubeID(videoUrl);
            let videoID = vimeoID || youtubeID;
            let videoType = vimeoID ? 'v' : 'yt';

            TPS.Video.openVideoModal(videoID, videoTitle, videoType);

        });

    }

    createImageModal(imageUrl, description, options) {
        
        let elem, modal, modalContent;

        if (!options) {
            options = {
                animationIn: 'slide-in-down',
                animationOut: 'slide-out-up',
                resetOnClose: true,
                appendTo: '#form',
                additionalOverlayClasses: 'tps-reveal tps-image-reveal'
            };
        }
        if (!description) {
            description = '';
        }

        modalContent = ' <button class="close-button" data-close type="button"><span class="sr-only">Close modal</span>&times;</button>';
        modalContent += '<div class="tps-reveal-content-wrapper">';
        modalContent += '<img class="tps-image-reveal-modal-image" src="' + imageUrl + '" alt="' + description + '" />';
        modalContent += '</div>';

        elem = $('#tps-modal-image');
        if (elem.length === 0) {

            elem = $('<div>').attr('id', 'tps-modal-image').addClass('reveal tps-image-reveal-modal').append(modalContent);     

            modal = new Foundation.Reveal(elem, options);

        } else {
            elem.empty().append(modalContent);
        }
        elem.foundation('open');        

    }


    createiFrameModal(frameUrl, description, options) {

        let elem, modal, modalContent;

        if (!options) {
            options = {
                animationIn: 'slide-in-down',
                animationOut: 'slide-out-up',
                resetOnClose: true,
                appendTo: '#form',
                additionalOverlayClasses: 'tps-reveal tps-iframe-reveal'
            };
        }

        if (!description) {
            description = '';
        }
        
        modalContent = ' <button class="close-button" data-close type="button"><span class="sr-only">Close modal</span>&times;</button>';
        modalContent += '<div class="tps-reveal-content-wrapper">';
        modalContent += '<div class="tps-frame-wrapper"><iframe class="tps-image-reveal-modal-frame" src="' + frameUrl + '">' + description + '</iframe></div>';
        modalContent += '</div>';

        elem = $('#tps-modal-frame');
        if (elem.length === 0) {

            elem = $('<div>').attr('id', 'tps-modal-frame').addClass('reveal tps-frame-reveal-modal').append(modalContent);

            modal = new Foundation.Reveal(elem, options);

        } else {
            elem.empty().append(modalContent);
        }
        elem.foundation('open');                

    }

};

TPS.Modal = new Modal();
